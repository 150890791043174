import { useState, useEffect, useReducer, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addCurrentDocURL,
  addMainScreenID,
  addScreenID,
  biDetailsList,
  getDriveFiles,
  getIsMaintenanceMode,
  getMSDocs,
  getMeeting,
  getNoticationsDetails,
  getProductsDetails,
  postMSDocs,
  postNoticationsDetails,
  postdashboardStateManagement,
  resetExceptionError,
  uiMenuList,
} from "../store/actions/index.js";
import ExcelRateLogo from "../assets/images/exclrate_logo.png";
import ExcelRateLogoIcon from "../assets/images/logo.svg";
import "./MainComponent.css";
import SecondSideBarComponent from "./SecondSideBarComponent.js";
import Select from "react-select";
import UploadFileIcon from "../assets/images/Documents.svg";
import NotesIcon from "../assets/images/notes.svg";
import CalenderIcon from "../assets/images/Calendar.svg";
import CollaboratorsIcon from "../assets/images/Collaborate.svg";
import LogoutIcon from "../assets/images/logout.png";
import { url } from "../store/sagas/API.js";
import ChevronRight from "../assets/images/Chevron-right.svg";
import { BiChevronsLeft, BiChevronsRight, BiRightArrow } from "react-icons/bi";
import { BsPersonFill } from "react-icons/bs";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Overlay,
  Popover,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Loader from "./Loader.js";
import Alert from "./Alert.js";
import Image from "../assets/images/icon.svg";
import NoDocImage from "../assets/images/nodoc.svg";
import Meeting from "./Meeting.js";
import BISelector from "./BISelector.js";
import ProductSelector from "./ProductSelector.js";
import MeetingSelector from "./MeetingSelector.js";
import { useLocation } from "react-router-dom";
import ChatbotComponent from "../base/chatbot/components";
import Breadcrumb from "./Breadcrumb.js";
import Calendar from "./Calendar.js";
import { Badge } from "react-bootstrap";
import { PiBellLight } from "react-icons/pi";
import { getRandomColor, createImageFromInitials } from "./Utils.js";
import FolderManager from "./FolderManager.js";
import Add from "../assets/images/add.svg";
import NotificationMenu from "./NotificationMenu.js";
import HelpInfo from "../utils/helpInfoProviderComponent.js";
import MaintenancePage from "../../standardpages/MaintenancePage.js";
import Logout from "./Logout.js";
const MainComponent = (props) => {
  const location = useLocation();
  const username = localStorage.getItem("full_name");
  const newMeetingClicked = localStorage.getItem("setNewMeetingClicked");
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const [isOpen, setIsOpen] = useState(props.isMenuCollapse ? false : true);
  const [isSecSideBarOpen, setIsSecSideBarOpen] = useState(false);
  const [secSideBarContent, setSecSideBarContent] = useState(false);
  const [issecSideBarClicked, setIsSecSideBarClicked] = useState(false);
  const [dashboardTitle, setDashboardTitle] = useState("");
  const dispatch = useDispatch();
  const isBIFetched = useSelector((state) => state.isBIFetched);
  const isUIListFetched = useSelector((state) => state.isUIListFetched);
  const uiListDetails = useSelector((state) => state.uiListDetails);
  const biDetails = useSelector((state) => state.biDetails);
  const isProductDetailsFetched = useSelector(
    (state) => state.isProductDetailsFetched
  );
  const getBIProductsData = useSelector((state) => state.getBIProductsData);
  const showLoader = useSelector((state) => state.showLoading);
  const isSessionExp = useSelector((state) => state.isSessionExpiry);
  const isSessionAPIError = useSelector((state) => state.isSessionAPIError);
  const apiError = useSelector((state) => state.apiError);
  const isInternalServerError = useSelector(
    (state) => state.isInternalServerError
  );
  const ISError = useSelector((state) => state.ISError);
  const [biData, setBiData] = useState([]);
  const [uiMenuData, setUIMenuData] = useState([]);
  const [uiPath, setUIPath] = useState([]);
  const [sidebarID, setSidebarID] = useState([]);
  const [parentID, setParentID] = useState([]);
  const [checkpath, setCheckpath] = useState(false);
  const [productData, setProductData] = useState([]);
  const [showPopover, setShowPopover] = useState(null);
  var pathname = window.location.pathname;
  const meetingData = useSelector((state) => state.meetingData);
  const selectedBI = useSelector((state) => state.selectedBI);
  const selectedMeeting = useSelector((state) => state.selectedMeeting);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProducts, setShowProducts] = useState(props.showProducts);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const popoverRef = useRef(null);
  const [files, setFiles] = useState([]);

  const isMSDocsDataFetched = useSelector((state) => state.isMSDocsDataFetched);
  const isMSDocsPostDone = useSelector((state) => state.isMSDocsPostDone);
  const screen_id = useSelector((state) => state.screen_id);
  const isScreenIDAdded = useSelector((state) => state.isScreenIDAdded);
  const isMSDocsPosted = useSelector((state) => state.isMSDocsPosted);
  const isMSDocsPostFetched = useSelector((state) => state.isMSDocsPostFetched);
  const isMainScreenIDAdded = useSelector((state) => state.isMainScreenIDAdded);
  const main_screen_id = useSelector((state) => state.main_screen_id);
  const screen_name = useSelector((state) => state.screen_name);
  const isPostDeleteSuccess = useSelector((state) => state.isPostDeleteSuccess);
  const fecthPostNotifData = useSelector((state) => state.fecthPostNotifData);
  const getDriveFilesData = useSelector((state) => state.getDriveFilesData);
  const isgetDriveFilesFetched = useSelector(
    (state) => state.isgetDriveFilesFetched
  );
  const [dragActive, setDragActive] = useState(false);
  const [isMSDocsSend, setIsMSDocsSend] = useState(false);
  const [isGetData, setIsGetData] = useState(isMSDocsPostDone);
  const [showMeetingPopup, setShowMeetingPopup] = useState(false);
  const getMeetingData = useSelector((state) => state.getMeetingData);
  const [sidebarWidth, setSidebarWidth] = useState(0 + "px");
  const [secSidebarWidth, setSecSidebarWidth] = useState(0 + "px");
  const isKbTemplates = location.pathname === "/kb/templates";

  const [isHovered, setIsHovered] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const newbuttonClicked = JSON.parse(localStorage.getItem("newbuttonClicked"));
  const [isURLMatching, setIsURLMatching] = useState(false);
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);
  const [notifCount, setNotifCount] = useState(0);
  const fecthNotifData = useSelector((state) => state.fecthNotifData);
  const [notifData, setNotifData] = useState();
  const [calenderIconShow, setCalenderIconShow] = useState(false);
  const [folderIconShow, setFolderIconShow] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState("");
  const [selectDashboard, setSelectDashboard] = useState(false);
  const [dashboardUrlMatching, setDashboardUrlMatching] = useState(false);
  const isMaintenanceMode = useSelector((state) => state.isMaintenanceMode);
  const handleMouseEnter = (itemName) => {
    setIsHovered(true);
    setHoveredItem(itemName);
  };
  const renderTooltip = (props) => (
    <Tooltip id="username-tooltip" {...props}>
      {username}
    </Tooltip>
  );

  const handleFolderManager = () => {
    setIsPopoverOpen(true);
    setFolderIconShow(true);
  };
  const handleFolderManagerSidebarClose = () => {
    setIsPopoverOpen(false);
    setFolderIconShow(false);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    setHoveredItem(null);
  };
  // ref
  const inputRef = useRef(null);
  const secSidebarRef = useRef(null);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;

    // Check if the URL meets a certain condition
    if (currentURL.includes("/bidetails")) {
      setIsURLMatching(true);
    }

    if (currentURL.includes("/dashboard")) {
      setDashboardUrlMatching(true);
    }
  }, []);

  useEffect(() => {
    if (screen_id && localStorage.getItem("bi_id")) {
      dispatch(
        postdashboardStateManagement(
          localStorage.getItem("bi_id"),
          screen_id,
          false,
          true
        )
      );
    }
  }, []);

  useEffect(() => {
    dispatch(biDetailsList());
    return () => {};
  }, []);

  useEffect(() => {
    if (localStorage.getItem("bi_id")) {
      dispatch(
        uiMenuList(
          localStorage.getItem("bi_id"),
          localStorage.getItem("product_id")
        )
      );
      dispatch(getProductsDetails(localStorage.getItem("bi_id")));
    } else {
      dispatch(uiMenuList());
    }
  }, [localStorage.getItem("bi_id")]);

  useEffect(() => {
    if (fecthNotifData) {
      setNotifData(fecthNotifData.data);
      setNotifCount(fecthNotifData.count);
    }
  }, [fecthNotifData]);

  // useEffect(() => {
  //   if (isMSDocsDataFetched) {
  //     if (getMSDocsdata.data) {
  //       setFiles(getMSDocsdata.data);
  //     }
  //   }
  // }, [isMSDocsPostDone, isMSDocsDataFetched, getScreenName]);

  // useEffect(() => {
  //   if (isScreenIDAdded) {
  //     dispatch(
  //       getMSDocs(localStorage.getItem("bi_id"), screen_id, main_screen_id)
  //     );
  //   }
  // }, [isScreenIDAdded]);
  useEffect(() => {
    if (isProductDetailsFetched) {
      if (getBIProductsData) {
        if (getBIProductsData.ok) {
          if (getBIProductsData.data) {
            setProductData(getBIProductsData.data.data);
            getBIProductsData.data.data.map((obj, i) => {
              if (obj.value === localStorage.getItem("product_id")) {
                setSelectedProduct(obj);
              }
            });
          }
        }
      }
    }
  }, [isProductDetailsFetched]);
  useEffect(() => {
    if (isUIListFetched) {
      if (uiListDetails) {
        console.log("ui men", uiListDetails);
        if (uiListDetails.data) {
          if (uiListDetails.data.message) {
            setUIMenuData(uiListDetails.data.message[0]);
            setUIPath(uiListDetails.data.message[1]);
            findSiblingsContent(uiListDetails.data.message[0]);
            if (uiListDetails.data.message[1] !== pathname) {
              uiListDetails.data.message[1].map((obj) => {
                if (obj.url === pathname) {
                  setCheckpath(true);
                } else {
                }
                return null;
              });
            }
          }
        }
      }
    }
  }, [isUIListFetched]);

  useEffect(() => {
    if (isBIFetched) {
      if (biDetails) {
        if (biDetails.ok) {
          if (biDetails.data && biDetails.data.bi_data) {
            setBiData(
              biDetails.data.bi_data.map((obj, i) => {
                if (obj.name === localStorage.getItem("bi_id")) {
                  dispatch({
                    type: "SET_SELECTED_BI",
                    payload: {
                      value: obj.name,
                      label: obj.bi_name,
                    },
                  });
                }
                return {
                  value: obj.name,
                  label: obj.bi_name,
                };
              })
            );
          }
        } else if (biDetails.status === "403") {
          return <div>{alert("Session Expired")}</div>;
        }
      }
    }
    return () => {};
  }, [isBIFetched]);
  // useEffect(() => {
  //   if (isMSDocsPostFetched && screen_name === "main_component") {
  //     setIsGetData(!isGetData);
  //     dispatch(
  //       getMSDocs(
  //         localStorage.getItem("bi_id"),
  //         screen_id,
  //         main_screen_id,
  //         "main_component",
  //         isGetData
  //       )
  //     );
  //     // setUploadProgress(0);
  //     // setIsPopoverOpen(false);
  //   }
  // }, [isMSDocsPosted, isMSDocsPostFetched, screen_name]);

  useEffect(() => {
    dispatch({ type: "SET_SELECTED_NEW_BI", value: true });
  }, []);

  function handleFile(files) {
    setIsMSDocsSend(!isMSDocsSend);
    dispatch(
      postMSDocs(
        localStorage.getItem("bi_id"),
        screen_id,
        "",
        files[0],
        "main_component",
        isMSDocsSend
      )
    );
  }

  useEffect(() => {
    if (getMeetingData) {
      if (getMeetingData.meeting_data) {
        let meeting_data =
          getMeetingData.meeting_data?.length > 0
            ? getMeetingData.meeting_data
            : [];
        if (meeting_data.length === 0) {
          dispatch({
            type: "SET_SELECTED_MEETING",
            payload: null,
          });
        }
        dispatch({
          type: "SET_MEETING_DATA",
          payload: meeting_data.map((obj, i) => {
            if (obj.notes_id === localStorage.getItem("note_id")) {
              dispatch({
                type: "SET_SELECTED_MEETING",
                payload: {
                  value: obj.notes_id,
                  label: obj.meeting,
                },
              });
            }
            return {
              value: obj.notes_id,
              label: obj.meeting,
            };
          }),
        });
      }
    }
    return () => {};
  }, [getMeetingData]);

  const handleSelectNewBI = () => {
    localStorage.removeItem("bi_id");
    localStorage.removeItem("product_id");
    dispatch({ type: "SET_SELECTED_NEW_BI", value: false });
    dispatch({
      type: "SET_SELECTED_BI",
      payload: null,
    });
  };
  const handleMeetingClick = (e) => {
    dispatch(getMeeting(localStorage.getItem("bi_id")));
    setShowMeetingPopup(true);
    setCalenderIconShow(true);
  };
  const handleNotesClick = (e) => {
    // navigate("/listofnotes");
    window.open("/listofnotes", "_blank");
  };
  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const calculateColumnWidths = () => {
      if (sidebarRef.current) {
        setSidebarWidth(sidebarRef.current.offsetWidth + "px");
      }
      if (secSidebarRef.current) {
        setSecSidebarWidth(secSidebarRef.current.offsetWidth + "px");
      }
    };

    window.addEventListener("resize", calculateColumnWidths);
    calculateColumnWidths();

    return () => {
      window.removeEventListener("resize", calculateColumnWidths);
    };
  });

  const OpenSidebar = (items, title, id) => {
    setSelectDashboard(true);
    setSelectedNavItem(id);
    setShowProducts(false);
    setDashboardTitle(title);
    setSecSideBarContent(items);
    setIsSecSideBarClicked(true);
    setIsSecSideBarOpen(true);
  };
  const closeSidebar = () => {
    setIsSecSideBarOpen(false);
    setIsSecSideBarClicked(false);
  };
  const handleShowTemplate = () => {
    navigate("/kb/templates");
  };
  function findParent(items, parent, title) {
    for (var i = 0; items.length > i; i++) {
      if (items[i].url === pathname) {
        dispatch(addScreenID(items[i].id, true));
        dispatch(addMainScreenID(items[i].id, true));
        localStorage.setItem("screen_id", items[i].id);
        dispatch(addCurrentDocURL(items[i].template_link, true));

        if (parent) {
          setParentID(parent.id);
          return parent;
        } else {
          return items[i];
        }
      } else if (items[i].children.length > 0) {
        let siblingParent = findParent(
          items[i].children,
          items[i],
          items[i].title
        );

        if (siblingParent) return items[i];
      }
    }

    return null;
  }
  const findSiblingsContent = (items) => {
    const siblings = findParent(items);
    if (siblings) {
      if (siblings.children.length > 0) {
        dispatch(addMainScreenID(siblings.id, true));
        localStorage.setItem("main_screen_id", siblings.id);
        setSidebarID(siblings.id);
        setDashboardTitle(siblings.title);
        setSecSideBarContent(siblings.children);
        setIsSecSideBarClicked(true);
      } else {
        setIsOpen(props.isMenuCollapse ? false : true);
      }
    } else {
      setIsOpen(props.isMenuCollapse ? false : true);
    }
  };
  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.includes("/bidetails")) {
      dispatch({ type: "SET_SELECTED_PERMISSION", value: "" });
    }
    const selectedItem = uiMenuData.find((item) => item.id === main_screen_id);
    if (selectedItem) {
      const permission = selectedItem.permission;
      dispatch({ type: "SET_SELECTED_PERMISSION", value: permission });
    }
  }, [uiMenuData]);

  useEffect(() => {
    const selectedItem = uiPath.find((item) => item.id === screen_id);
    if (selectedItem) {
      const permission = selectedItem.permission;
      dispatch({ type: "SET_SELECTED_SUB_MENU_PERMISSION", value: permission });
    }
  }, [uiPath]);
  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  const handleCloseMeetingPopup = () => {
    setShowMeetingPopup(false);
    setCalenderIconShow(false);
  };

  const handleSelect = (e) => {
    localStorage.setItem("bi_id", e.value);
    // setSelectedBI(e);
    dispatch({
      type: "SET_SELECTED_BI",
      payload: e,
    });
    if (location.pathname == "/notes") {
      dispatch(getMeeting(e.value));
    }
    window.location.reload();
  };

  const handleMeetingSelect = (e) => {
    localStorage.setItem("note_id", e.value);
    // setSelectedBI(e);
    dispatch({
      type: "SET_SELECTED_MEETING",
      payload: e,
    });
    window.location.reload();
  };
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotificationMenu(false);
        dispatch(postNoticationsDetails(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (fecthPostNotifData) {
      dispatch(getNoticationsDetails(false));
    }
  }, [fecthPostNotifData]);

  useEffect(() => {
    dispatch(getNoticationsDetails(false));
  }, []);

  const notificationToggle = () => {
    setShowNotificationMenu(true);
  };

  const handleSelectProduct = (e) => {
    localStorage.setItem("product_id", e.value);
    localStorage.setItem("product_value", e.label);
    setSelectedProduct(e);
    window.location.reload();
  };

  //if user not select the BI then it shows red color
  const redBorderStyle = {
    border: "2px solid red",
  };
  const customStyles = (borderStyle) => ({
    control: (provided, state) => ({
      ...provided,
      ...borderStyle,
      borderRadius: "10px",
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: state.isSelected ? "#ecf4f3" : "white",
      color: "black",
    }),
  });

  var content = uiMenuData.map((item, index) => {
    if (item.children.length > 0) {
      return (
        <div
          id={item.id}
          className={
            sidebarID === item.id
              ? "firstsidebar-item active"
              : "firstsidebar-item"
          }
          onMouseEnter={(e) => OpenSidebar(item.children, item.title, item.id)}
          // onMouseLeave={() => closeSidebar()}
        >
          <div
            className="firstsidebar-title firstsidebar-item"
            style={{
              bottom:
                item.title === "Knowledge Base"
                  ? "65px"
                  : item.title === "Reports"
                  ? "110px"
                  : "0px",
              position:
                item.title === "Knowledge Base"
                  ? "absolute"
                  : item.title === "Reports"
                  ? "absolute"
                  : undefined,
              width:
                item.title === "Knowledge Base"
                  ? "100%"
                  : item.title === "Reports"
                  ? "100%"
                  : "",
              // backgroundColor:
              //   selectedNavItem === item.id ? "var(--background-color)" : "",
            }}
            onMouseEnter={() => handleMouseEnter(item.title)}
            onMouseLeave={() => handleMouseLeave()}
          >
            <span>{item.icon && <img alt="" src={url + item.icon} />}</span>
            <div
              style={{ display: dashboardUrlMatching ? "block" : "none" }}
              className="link_text"
            >
              {item.title}
            </div>
          </div>
        </div>
      );
    } else {
      if (item.is_menu > 0) {
        return (
          <NavLink
            to={item.url}
            key={index}
            style={{
              backgroundColor: dashboardUrlMatching
                ? "var(--background-color)"
                : "",
              alignItems: "center",
            }}
            className="dashboard-item"
          >
            <span>{item.icon && <img alt="" src={url + item.icon} />}</span>
            <div
              style={{ display: dashboardUrlMatching ? "block" : "none" }}
              className="link_text"
            >
              {item.title}
            </div>
          </NavLink>
        );
      }
    }
  });

  useEffect(() => {
    if (uiListDetails && uiListDetails.data) {
      if (uiListDetails.data.is_maintenance) {
        dispatch({
          type: "IS_MAINTENANCE_MODE",
          value: uiListDetails.data.is_maintenance === 1,
        });
      }
    }
  }, [uiListDetails]);

  if (isMaintenanceMode) {
    return (
      <>
        <Logout />
        <MaintenancePage />
      </>
    );
  }
  return (
    <>
      {!isMaintenanceMode && (
        <>
          {isInternalServerError &&
            Alert.warning({
              title: "Something went wrong!",
              // html: ISError,
              // text: "Something went wrong. Please try again",
              text: "Try refreshing your browser. If the issue persists, please contact Excelrate support",
              icon: "warning",
              preConfirm: (isConfirm) => {
                if (isConfirm) {
                  dispatch(resetExceptionError());
                } else {
                }
              },
            })}
          {isSessionAPIError &&
            Alert.warning({
              title: "Warning",
              html: apiError,
              icon: "warning",
              preConfirm: (isConfirm) => {
                if (isConfirm) {
                  dispatch(resetExceptionError());
                } else {
                }
              },
            })}
          {isSessionExp &&
            Alert.warning({
              title: "Warning",
              html: "Session Expired",
              icon: "warning",
              preConfirm: (isConfirm) => {
                if (isConfirm) {
                  dispatch(navigate("/logout"));
                } else {
                }
              },
            })}
          {showLoader && <Loader />}
          {checkpath || props.showMenu ? (
            <div className="main-menu-display">
              <aside
                style={{
                  width: dashboardUrlMatching ? "220px" : "50px",
                  position: "sticky",
                  left: 0,
                  // zIndex: 100,
                }}
                className="sidebar"
                ref={sidebarRef}
              >
                <div className="top_section">
                  {dashboardUrlMatching ? (
                    <img
                      alt=""
                      style={{ width: "150px" }}
                      src={ExcelRateLogo}
                    />
                  ) : (
                    <img
                      alt=""
                      src={ExcelRateLogoIcon}
                      style={{ height: "25px" }}
                    />
                  )}
                </div>
                <div style={{ paddingTop: 50 }}>{content}</div>
                <div
                  className=""
                  style={{
                    bottom: 10,
                    position: "absolute",
                    // background: "white",
                    borderRadius: "",
                    margin: "",
                    width: "50px",
                    minWidth: 50,
                  }}
                >
                  <div className="firstsidebar-title">
                    {!dashboardUrlMatching ? (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <span>
                          <BsPersonFill />
                        </span>
                      </OverlayTrigger>
                    ) : (
                      <span>
                        <BsPersonFill />
                      </span>
                    )}

                    <div
                      style={{
                        display: dashboardUrlMatching ? "block" : "none",
                        cursor: "pointer",
                      }}
                      className="link_text"
                    >
                      {username}
                    </div>
                  </div>
                </div>
              </aside>
              <main
                className="main-content"
                id="main-content-id"
                style={{
                  width: dashboardUrlMatching
                    ? "calc(100% - 220px"
                    : "calc(100% - 50px)",
                  position: "fixed",
                  marginLeft: dashboardUrlMatching ? 220 : 50,
                }}
              >
                {showMeetingPopup && (
                  <Calendar onClose={handleCloseMeetingPopup} />
                )}
                <div className="header">
                  <div className="row">
                    <div className={"col-lg-4 col-md-4 col-sm-6"}>
                      <div style={{ display: "flex" }}>
                        <BISelector
                          showSelector={props.showSelector}
                          selectedBI={selectedBI}
                          biData={biData}
                          handleSelect={handleSelect}
                        />
                        {isURLMatching &&
                          (selectedPermission === "write" ||
                            selectedPermission === "admin") && (
                            <button
                              className="new-bi-button"
                              onClick={() => handleSelectNewBI()}
                            >
                              <img
                                alt="logo"
                                src={Add}
                                width={16}
                                height={16}
                                style={{ marginRight: "5px" }}
                              />
                              <span
                                style={{ fontSize: "var(--text-font-size)" }}
                              >
                                New&nbsp;BI
                              </span>
                            </button>
                          )}
                        <ProductSelector
                          showSelector={props.showProducts}
                          selectedProduct={selectedProduct}
                          productData={productData}
                          handleSelectProduct={handleSelectProduct}
                        />
                        {newMeetingClicked === "false" && (
                          <MeetingSelector
                            showSelector={true}
                            selectedMeeting={selectedMeeting}
                            meetingData={meetingData}
                            handleSelect={handleMeetingSelect}
                          />
                        )}
                      </div>
                    </div>
                    <div className={"col-lg-8 col-md-8 col-sm-6"}>
                      <div className="justify-content-end">
                        <a
                          href="/logout"
                          title="Logout"
                          style={{
                            textDecoration: "none",
                            color: "var(--background-color)",
                          }}
                        >
                          <img
                            alt="icon"
                            className="logout-icon"
                            src={LogoutIcon}
                          />
                        </a>
                        <span
                          className="header-icons"
                          title="Notifications"
                          style={{
                            background: showNotificationMenu && "#0A5F59",
                          }}
                          onClick={() => notificationToggle()}
                        >
                          <PiBellLight
                            size={21}
                            style={{
                              color:
                                showNotificationMenu &&
                                "var(--background-color)",
                            }}
                          />
                          {notifCount > 0 && (
                            <Badge
                              bg="danger"
                              className="notification-badge"
                              pill
                            >
                              {notifCount}
                            </Badge>
                          )}
                          {showNotificationMenu && (
                            <NotificationMenu
                              notifData={notifData}
                              notificationRef={notificationRef}
                              fecthPostNotifData={fecthPostNotifData}
                            />
                          )}
                        </span>
                        {/* <span
                      className="header-icons"
                      title="Collaborators"
                      style={{
                        background:
                          props.showScreen === "collaborators" && "#0A5F59",
                      }}
                    >
                      <img
                        alt=""
                        style={{
                          filter:
                            props.showScreen === "collaborators" &&
                            "invert(100%)",
                        }}
                        src={CollaboratorsIcon}
                      />
                    </span> */}
                        <div onClick={(e) => handleNotesClick(e)}>
                          <span
                            className="header-icons"
                            title="Notes"
                            style={{
                              background:
                                props.showScreen === "notes" && "#0A5F59",
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                filter:
                                  props.showScreen === "notes" &&
                                  "invert(100%)",
                              }}
                              src={NotesIcon}
                            />
                          </span>
                        </div>
                        <div onClick={(e) => handleMeetingClick(e)}>
                          <span
                            className="header-icons"
                            title="Calender"
                            style={{
                              background: calenderIconShow ? "#0A5F59" : "",
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                filter: calenderIconShow ? "invert(100%)" : "",
                              }}
                              src={CalenderIcon}
                            />
                          </span>
                        </div>
                        <span
                          onClick={handleFolderManager}
                          className="header-icons"
                          title="Documents"
                          style={{
                            background: folderIconShow ? "#0A5F59" : "",
                          }}
                        >
                          <img
                            alt=""
                            src={UploadFileIcon}
                            style={{
                              filter: folderIconShow ? "invert(100%)" : "",
                            }}
                          />
                        </span>
                        {isPopoverOpen && (
                          <FolderManager
                            onClose={handleFolderManagerSidebarClose}
                          />
                        )}
                        {/* <div ref={searchRef}> */}
                        <div>
                          <input
                            className="global-serach-field"
                            type="search"
                            style={{
                              width: 300,
                              float: "right",
                              marginBottom: 0,
                            }}
                            placeholder="Search"
                            // value={searchTerm}
                            // onChange={handleSearch}
                          />
                          {/* {filteredResults.length > 0 && (
                            <div
                              style={{
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                position: "absolute",
                                right: 220,
                                top: 53,
                                backgroundColor: "white",
                                border: "1px solid #ccc",
                                zIndex: 2,
                                width: "300px",
                                maxHeight: "520px",
                                overflowY: "auto",
                                borderBottomLeftRadius: "5px",
                                borderBottomRightRadius: "5px",
                              }}
                            >
                              {filteredResults.map((result, index) => (
                                <div
                                  className="global-search-result"
                                  key={index}
                                  style={{ padding: "10px", cursor: "pointer" }}
                                  onClick={() =>
                                    handleResultClick(result.navigation_url)
                                  }
                                >
                                  {result.title}
                                </div>
                              ))}
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <hr
              style={{
                margin: "0",
                borderBottom: "2px ridge",
                borderTop: "0",
                width: "100%",
              }}
            /> */}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "calc(100vh - 78px)",
                  }}
                >
                  <div
                    id="sidebar"
                    ref={secSidebarRef}
                    style={{
                      position: "absolute",
                      left: 5,
                      width: "250px",
                      borderRight: "thin ridge",
                      height:
                        isSecSideBarOpen && secSideBarContent ? "90%" : "",
                      boxShadow:
                        isSecSideBarOpen && secSideBarContent
                          ? "0 2px 4px 2px rgba(0,0,0,.2)"
                          : "",
                      overflow: "hidden",
                      zIndex: "9999",
                      borderRadius: "10px",
                      backgroundColor: "var(--sub-menu-bg-color)",
                    }}
                    onMouseLeave={() => closeSidebar()}
                  >
                    {issecSideBarClicked &&
                    isSecSideBarOpen &&
                    secSideBarContent ? (
                      <div>
                        <div
                          style={{
                            position: "sticky",
                            top: 0,
                            padding: isSecSideBarOpen ? "0" : "30px",
                          }}
                        >
                          <div
                            className="menu-dashboard-title"
                            style={{
                              justifyContent: isSecSideBarOpen
                                ? "space-between"
                                : "center",
                            }}
                          >
                            {isSecSideBarOpen && (
                              <b style={{ textTransform: "uppercase" }}>
                                {dashboardTitle}
                              </b>
                            )}
                            <div
                              style={{
                                marginLeft: isSecSideBarOpen ? "0px" : "-5px",
                              }}
                              className="bars"
                            ></div>
                          </div>
                        </div>
                        <div>
                          {secSideBarContent.map((item, index) => (
                            <div
                              key={index}
                              onMouseEnter={() => handleMouseEnter(item.title)}
                              onMouseLeave={() => handleMouseLeave()}
                            >
                              <SecondSideBarComponent
                                key={index}
                                item={item}
                                childclass="linkfirstsidebar-item"
                                parentID={null}
                                setIsSecSideBarOpen={(value) => {
                                  setIsSecSideBarOpen(value);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {!localStorage.getItem("bi_id") && !props.showTemplate ? (
                    <div
                      style={{
                        paddingTop: 20,
                        paddingLeft: 40,
                        width:
                          isOpen && issecSideBarClicked
                            ? "calc(100% - 560px)"
                            : issecSideBarClicked
                            ? "calc(100% - 350px)"
                            : "100%",
                      }}
                    >
                      <div style={{ fontSize: "var(--sub-heading-font-size)" }}>
                        Please Select/Create Business Initiative
                      </div>
                    </div>
                  ) : !localStorage.getItem("product_id") &&
                    !props.showTemplate &&
                    showProducts ? (
                    <div
                      style={{
                        paddingTop: 20,
                        paddingLeft: 40,
                        width:
                          isOpen && issecSideBarClicked
                            ? "calc(100% - 560px)"
                            : issecSideBarClicked
                            ? "calc(100% - 350px)"
                            : "100%",
                      }}
                    >
                      <div style={{ fontSize: "var(--sub-heading-font-size)" }}>
                        Please Select the Product
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        paddingTop: 20,
                        paddingLeft: 40,
                        // width: issecSideBarClicked ? "calc(100% - 300px)" : "100%",
                        width: "100%",
                        // overflow: "auto",
                        // height: "calc(100vh - 80px)",
                      }}
                    >
                      {localStorage.getItem("bi_id") === null &&
                        props.showError &&
                        !newbuttonClicked && (
                          <div
                            style={{
                              fontSize: "var(--sub-heading-font-size)",
                              fontWeight: "var(--heading-font-weight)",
                              color: "var(--required-color)",
                            }}
                          >
                            Please Select/Create Business Initiative
                          </div>
                        )}
                      <div>{props.headercontent}</div>

                      <div
                        className="maincontent"
                        style={{
                          paddingTop: "40px",
                          height: props.showFullScreen
                            ? "100%"
                            : props.showButtons
                            ? "100%"
                            : "100%",
                          overflow: "auto",
                          // margin: "auto",
                          // msOverflowStyle: "none",
                          // scrollbarWidth: "none",
                        }}
                      >
                        {props.maincontent}
                      </div>

                      {/* <ChatbotComponent /> */}
                      {props.showButtons && (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              top: "80px",
                              backgroundColor: "white",
                              width: "100%",
                            }}
                          >
                            <Breadcrumb
                              SecondSideBarClicked={(value) =>
                                setIsSecSideBarOpen(value)
                              }
                              isSecSideBarOpen={isSecSideBarOpen}
                              breadcrumbItems={[
                                {
                                  text: props.breadcrumbParent,
                                  link: props.breadcrumbParentLink,
                                },
                                {
                                  text: props.breadcrumbChild,
                                  link: props.breadcrumbChildLink,
                                },
                                {
                                  text: props.breadcrumbSubChild,
                                  link: props.breadcrumbSubChildLink,
                                },
                              ]}
                            />
                          </div>
                          <div
                            className="main-footer"
                            style={{
                              display: props.showTemplateButton ? "flex" : "",
                              justifyContent: props.showTemplateButton
                                ? "space-between"
                                : "",
                              float: props.showTemplateButton ? "" : "right",
                              left: props.showTemplateButton ? 370 : "",
                              position: "absolute",
                              top: "80px",
                              right: 0,
                              display: "flex",
                            }}
                          >
                            {props.showTemplateButton && (
                              <div className="main-footer-button-container">
                                <Button
                                  onClick={() => {
                                    handleShowTemplate();
                                  }}
                                  className="show-template-button"
                                >
                                  Go To Templates
                                </Button>
                              </div>
                            )}
                            {props.showExecutionEditButton &&
                              (props.selectedPermission === "write" ||
                                props.selectedPermission === "admin") && (
                                <div
                                  className="main-footer-button-container"
                                  style={{ marginRight: "20px" }}
                                >
                                  {!props.ClickOnEdit ? (
                                    <Button
                                      onClick={() => {
                                        props.handleExecutionEditClick();
                                      }}
                                      className="back-button-style"
                                    >
                                      Edit
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        props.handleExecutionSaveClick();
                                      }}
                                      className="save-button-style"
                                    >
                                      Save
                                    </Button>
                                  )}
                                </div>
                              )}
                            {props.showMetricButton &&
                              (props.selectedPermission === "write" ||
                                props.selectedPermission === "admin") && (
                                <div
                                  className="main-footer-button-container"
                                  style={{ marginRight: "20px" }}
                                >
                                  <Button
                                    onClick={props.handleAddMetricsPopup}
                                    type="button "
                                    className="metrics-create-button"
                                  >
                                    <span style={{ paddingRight: "5px" }}>
                                      +
                                    </span>
                                    Create Metric
                                  </Button>
                                </div>
                              )}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Button
                                onClick={props.handleBackButton}
                                className="back-button-style"
                              >
                                Back
                              </Button>
                              {!props.NextButtonHide && (
                                <Button
                                  type={props.type ? props.type : "button"}
                                  className="next-button-style"
                                  disabled={props.NextButtonDisabled && "true"}
                                  onClick={props.handleNext}
                                >
                                  Next
                                </Button>
                              )}
                              <span style={{ marginLeft: "20px" }}>
                                <HelpInfo
                                  documentName={props.documentName}
                                  helpKey={props.helpKey}
                                  image={props.image}
                                />
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </main>
            </div>
          ) : (
            uiPath.length > 0 && (
              <div>
                <h4>No access for this page</h4>
                <br />
                <br />
                <h6>Accessible pages are given below</h6>
                <div>
                  {uiPath.map((obj) => {
                    return (
                      <>
                        <a href={obj.url}>{obj.title}</a>
                        <br />
                      </>
                    );
                  })}
                </div>
              </div>
            )
          )}
        </>
      )}
    </>
  );
};
export default MainComponent;
