import { useState, useEffect, useReducer, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MainComponent from "../MainComponent";
import {
  getKnowledgeBase,
  postCopyKnowledgeBaseTemplate,
} from "../../store/actions/index.js";
import { url } from "../../store/sagas/API.js";
import { FaDownload } from "react-icons/fa";
import SheetImage from "../../assets/images/icons8-excel.svg";
import DocImage from "../../assets/images/icons8-microsoft-word.svg";
import PDFImage from "../../assets/images/pdf-svgrepo-com.svg";
import PPTImage from "../../assets/images/icons8-powerpoint.svg";
import OtherImage from "../../assets/images/icons8-document.svg";
import { BsSearch } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import Checkmark from "../../assets/images/Checkmark.png";
import TabIcon from "../../assets/images/new-tab-icon.svg";
import TagSearch from "./TagSearch";

import "./Templates.css";
import Login from "../Login.js";

const Templates = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedTemplateBTN = useSelector((state) => state.selectedTemplateBTN);
  const [previousUrl, setPreviousUrl] = useState("");
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [description, setDescription] = useState(null);
  const [disclaimer, setDisclaimer] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [downloadlink, setDownloadlink] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [openSections, setOpenSections] = useState({});
  const [fileType, setFileType] = useState("file");
  const [templateId, setTemplateId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [defaultOpenSection, setDefaultOpenSection] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [templatesBTNClose, setTemplatesBTNClose] = useState(false);
  const getKnowledgeBaseData = useSelector(
    (state) => state.getKnowledgeBaseData
  );

  const CollapseToggle = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleCollapse = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className={`collapsible-section ${isOpen ? "open" : ""}`}>
        <div className="section-header" onClick={toggleCollapse}>
          <div
            style={{
              fontSize: "var(menu-font-size)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            {title}
          </div>
          <span className={`arrow ${isOpen ? "open" : ""}`}>&#x25B6;</span>
        </div>
        {isOpen && <div className="section-content">{children}</div>}
      </div>
    );
  };

  // useEffect(() => {
  //   setShowDetails(false);
  //   if (old_screen_id) {
  //     if (getKnowledgeBaseData) {
  //       const sectionWithMatchingScreenId = getKnowledgeBaseData.find(
  //         (sectionData) => {
  //           const sectionItems = Object.values(sectionData)[0];
  //           return sectionItems.some(
  //             (item) => item.screen_id === old_screen_id
  //           );
  //         }
  //       );

  //       if (sectionWithMatchingScreenId) {
  //         const sectionItems = Object.values(sectionWithMatchingScreenId)[0];
  //         const matchingItem = sectionItems.find(
  //           (item) => item.screen_id === old_screen_id
  //         );
  //         if (matchingItem) {
  //           setSearchTerm(matchingItem.tag);
  //         }
  //       }
  //     } else {
  //       setSearchTerm("Ansoff Matrix");
  //     }
  //   }
  // }, [getKnowledgeBaseData]);

  // useEffect(() => {
  //   dispatch(getKnowledgeBase(localStorage.getItem("old_screenid")));
  //   setOpenSections((prevOpenSections) => ({
  //     ...prevOpenSections,
  //     [defaultOpenSection]: true,
  //   }));
  // }, []);

  useEffect(() => {
    if (getKnowledgeBaseData) {
      if (getKnowledgeBaseData.templates) {
        setTemplates(getKnowledgeBaseData?.templates);
      }
      if (getKnowledgeBaseData.tags) {
        setTagsList(getKnowledgeBaseData.tags);
      }
    }

    // setShowDetails(false);
    // setSelectedTemplate(null);
  }, [getKnowledgeBaseData]);

  const handleBack = () => {
    // navigate(localStorage.getItem("old_screenurl"));
  };

  const toggleSection = (index) => {
    if (defaultOpenSection !== index) {
      setDefaultOpenSection(index);
    }
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [index]: !prevOpenSections[index],
    }));
    setShowDetails(false);
    setSelectedTemplate(null);
  };

  useEffect(() => {
    dispatch(getKnowledgeBase());
  }, []);
  // const handleSearchInputChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  // const handleClear = () => {
  //   // dispatch({ type: "SET_OLD_SCREEN_ID", payload: false });
  //   localStorage.setItem("old_screenid", null);
  //   setSearchTerm("");
  //   dispatch(getKnowledgeBase());
  // };
  // const handleSearch = () => {
  //   dispatch(getKnowledgeBase(false, searchTerm));
  // };

  // const handleDownload = (e, downloadlink, fileType) => {
  //   let file = downloadlink;
  //   dispatch(
  //     postCopyKnowledgeBaseTemplate(
  //       localStorage.getItem("old_screenid"),
  //       localStorage.getItem("bi_id"),
  //       file,
  //       templateId
  //     )
  //   );
  // };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    if (templatesBTNClose) {
      setIsModalOpen(false);
      navigate('/operatingplan')
      setTemplatesBTNClose(false);
      dispatch({ type: "SET_TEMPLATE_BTN_SELECTED", value: false });
    } else {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (selectedTemplateBTN === true) {
      setIsModalOpen(true);
      setTemplatesBTNClose(true);
      const templates = getKnowledgeBaseData?.templates.find(
        (item) => item.Finance
      );
      if (templates) {
        const financeTemplate = templates.Finance[0];
        handleTemplateClick(null, financeTemplate);
      }
    }
  }, [selectedTemplateBTN]);
  const handleTemplateClick = (e, item) => {
    localStorage.setItem("old_screenid", item.screen_id);
    setSelectedTemplate(item);
    setDescription(item.desc);
    setTags(item.tag);
    setTitle(item.title);
    setDownloadlink(item.template);
    setDisclaimer(item.disclaimer);
    setThumbnail(url + item.thumbnail);
    setFileType(item.file_type);
    setIsModalOpen(true);
    setTemplateId(item.name);
  };

  // const handleSectionSelect = (selectedOption) => {
  //   dispatch(getKnowledgeBase(selectedOption.value));
  // };

  const content = (
    <div
      style={{
        display: "flex",
        height: "100%",
        paddingLeft: "10px",
        paddingTop: "10px",
      }}
    >
      <div
        style={{
          width: showDetails ? "30%" : "100%",
          height: "100%",
          paddingRight: "10px",
        }}
      >
        {/* <div className="search-input" style={{ display: "flex" }}>
          <input
            className="form-control search_input"
            type="search"
            style={{ marginBottom: 20 }}
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchInputChange}
            onInput={(e) => {
              if (!e.target.value) {
                handleClear();
              }
            }}
          />
          <BsSearch
            style={{
              backgroundColor: "rgb(10, 95, 89)",
              width: "35px",
              height: "35px",
              color: "white",
              marginBottom: "20px",
              marginLeft: "5px",
              padding: "5px",
            }}
            onClick={handleSearch}
          />
        </div> */}
        <TagSearch tags={tagsList} />
        {templates &&
          templates.length > 0 &&
          templates.map((category) => (
            <CollapseToggle
              title={Object.keys(category)[0]}
              key={Object.keys(category)[0]}
            >
              {category[Object.keys(category)[0]].length > 0 &&
                category[Object.keys(category)[0]].map((item) => (
                  <div
                    key={item.name}
                    className="card-content"
                    onClick={(e) => {
                      handleTemplateClick(e, item);
                    }}
                  >
                    <img
                      className="card-image"
                      src={
                        item.img
                          ? url + item.img
                          : item.template?.split(".").pop() === "xls" ||
                            item.template?.split(".").pop() === "xlsx" ||
                            item.template?.split(".").pop() === "csv"
                          ? SheetImage
                          : item.template?.split(".").pop() === "pdf"
                          ? PDFImage
                          : item.template?.split(".").pop() === "doc" ||
                            item.template?.split(".").pop() === "docx"
                          ? DocImage
                          : item.template?.split(".").pop() === "ppt"
                          ? PPTImage
                          : OtherImage
                      }
                    />
                    <label className="card-label">{item.title}</label>
                  </div>
                ))}
            </CollapseToggle>
          ))}
      </div>
      <div
        style={{
          display: showDetails ? "flex" : "none",
          flexDirection: "column",
          width: "70%",
          borderLeft: "1px solid #ccc",
          height: "100%",
          alignItems: "center",
          padding: "10px",
        }}
      ></div>
      {isModalOpen && (
        <div
          style={{
            width: "50%",
          }}
          className={"sidenav"}
        >
          <div
            className="gtm-sidebar-header"
            style={{
              borderBottom: "1px solid lightgrey",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "var(--text-font-size)",
                marginTop: "5px",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              {"Templates > " + title}
            </div>
            <a href="#" className="closebtn" onClick={closeModal}>
              &times;
            </a>
          </div>
          <div className="gtm-sidebar-body">
            <div style={{ flex: "1" }}>
              <p className="templates-rhpo-data">
                <strong>Title: </strong>
                {title}
              </p>
              <p className="templates-rhpo-data">
                <strong>Tags: </strong>
                {tags}
              </p>
              <p className="templates-rhpo-data">
                <strong>Description: </strong>
                {description}
              </p>
              <img
                alt="thumbnail"
                src={thumbnail}
                style={{
                  width: "300px",
                  fontSize: "var(--sub-heading-font-size)",
                }}
              />
              <p style={{ paddingTop: "10px" }} className="templates-rhpo-data">
                <strong>Disclaimer: </strong> {disclaimer}
              </p>
              <a
                href={fileType === "link" ? downloadlink : url + downloadlink}
                target={"_blank"}
                download
                style={{
                  fontSize: "var(----text-font-size)",
                  display: "flex",
                  marginRight: "5px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                // onClick={(e) => {
                //   handleDownload(e, downloadlink, fileType);
                // }}
              >
                {fileType !== "link" && (
                  <FaDownload
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  />
                )}
                {/* (
                <img
                  alt=""
                  src={TabIcon}
                  style={{ width: "15px", height: "15px" }}
                />
              ) */}
                {fileType === "link" ? "Use Template" : "Download"}
              </a>
            </div>
          </div>
        </div>
      )}
      {/* <AiOutlineClose
        size={26}
        onClick={handleBack}
        style={{ cursor: "pointer" }}
      /> */}
    </div>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={false}
        showMenu={true}
        showFullScreen={true}
        isMenuCollapse={true}
        breadcrumbParent={"Knowledge Base"}
        breadcrumbChild={"Templates"}
      />
    );
  }
};

export default Templates;
