import { put } from "redux-saga/effects";
import {
  userLoginSuccess,
  exceptionError,
  biDetailsListSuccess,
  biDetailsGetSuccess,
  biDetailsPostSuccess,
  bisubsectorGetSuccess,
  bibusinesstypeGetSuccess,
  bibusinesstypePostSuccess,
  biproductdetailsGetSuccess,
  biproductdetailsPostSuccess,
  uiMenuListSuccess,
  vcListGetSuccess,
  vcCreateGetSuccess,
  vcChangeTableDataSuccess,
  addCustomerVCSelectionSuccess,
  getCustomerVCSelectionSuccess,
  loadingStart,
  exceptionSessionExpire,
  exceptionAPIError,
  getOrgDetailsSuccess,
  removeCustomerVCSelectionSuccess,
  getProductsDetailsSuccess,
  getMarketSizeDetailsSuccess,
  getCompetitorDetailsSuccess,
  getFeatureTypeDetailsSuccess,
  postCompAnalDetailsSuccess,
  postCompetitorDetailsSuccess,
  postMarketSizeDetailsSuccess,
  getCompAnalDetailsSuccess,
  postFeatureDetailsSuccess,
  getMetricsSuccess,
  postMetricsSuccess,
  postMSDocsSuccess,
  getMSDocsSuccess,
  getNotesSuccess,
  postNotesSuccess,
  getAnsoffMatrixDetailsSuccess,
  postMeetingSuccess,
  getMeetingSuccess,
  getKnowledgeBaseSuccess,
  postCopyKnowledgeBaseTemplateSuccess,
  getXLExecutionPlanDetailsSuccess,
  postStrategyFunctionsSuccess,
  postStrategyGoalSuccess,
  getInitiativeTrackerDetailsSuccess,
  // getDepartmentsSuccess,
  getMasterTaskPriorityAndStatusDataSuccess,
  getEmployeeDataSuccess,
  getExistingGoalsSuccess,
  getExistingFunctionsSuccess,
  getTaskDetailsSuccess,
  postTaskCommentsSuccess,
  postFunGoalDependenciesSuccess,
  postFunGoalKeyDecisionsSuccess,
  postFunGoalKeyRiskIssuesSuccess,
  getFunctionalGoalsSuccess,
  postFunctionalGoalReviewMeetingDataSuccess,
  getFunctionalGoalReviewMeetingDataSuccess,
  postFunctionalGoalReviewMeetingProgressDataSuccess,
  postFunctionalGoalReviewMeetingNextStepsSuccess,
  getdashboardStateManagementSuccess,
  postdashboardStateManagementSuccess,
  getExecutionReadinessDetailsSuccess,
  getStrategyReadinessDetailsSuccess,
  postStrategyReadinessDetailsSuccess,
  getCalenderMeetingDetailsSuccess,
  getDashboardTaskDetailsSuccess,
  getEcosystemMappingSectionDetailsSuccess,
  postEcosystemMappingSectionDetailsSuccess,
  postNoticationsDetailsSuccess,
  getNoticationsDetailsSuccess,
  getExecutionReadinessFunctionalAreaDetailsSuccess,
  getAnsoffMatrixGridDetailsSuccess,
  postAnsoffMatrixDetailsSuccess,
  getFileManagerDetailsSuccess,
  postFileManagerDetailsSuccess,
  postExecutionReadinessDetailsSuccess,
  getExectionReadinessReviewDetailsSuccess,
  postExecutionReadinessCustomerInputDetailsSuccess,
  postExecutionReadinessCustomerAnswerChoiceDetailsSuccess,
  postNewStrategyReadinessFunctionsSuccess,
  getStrategyReadinessExistedFunctionsDetailsSuccess,
  getStrategyReadinessFunctionalAreaDetailsSuccess,
  getStrategyReadinessReviewDetailsSuccess,
  postStrategyReadinessCustomerInputDetailsSuccess,
  postStrategyReadinessCustomerAnswerChoiceDetailsSuccess,
  postFolderManagerAddNewSuccess,
  postFolderManagerEditSuccess,
  postFolderManagerDeleteSuccess,
  postUploadFileInSpecificFolderSuccess,
  getDriveFilesSuccess,
  getProjectSummaryDetailsSuccess,
  postLocalFolderManagerSuccess,
  getLocalFolderManagerSuccess,
  postLocalFolderManagerFileDeleteSuccess,
  postScreenCommentsSuccess,
  getScreenCommentsSuccess,
  userUpdatePasswordSuccess,
  userForgotPasswordSuccess,
  postBiFGoalStatusSuccess,
  getBiFGoalStatusSuccess,
  getGlobalSearchFilterSuccess,
  getIsMaintenanceModeSuccess,
  getXLStrategyPlanDetailsSuccess,
  postXLStrategyPlanDetailsSuccess,
  getExportWorkboardCsvfileSuccess,
} from "../actions";
import apisauce from "apisauce";
import Cookies from "js-cookie";
import eventEmitter from "../emitter/emitter";
import { getApiEndpoint } from "../apiConfig";
// import { encode } from "base-64";
export const url = await getApiEndpoint();
Cookies.set("ABC", "ABC", {
  secure: true, // Ensure cookie is sent only over HTTPS
  sameSite: "None", // Allow the cookie to be sent in cross-site requests
});

const api = apisauce.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 600000,
});
const navMoniter = (response) => {};
api.addMonitor(navMoniter);

export function* userLoginSaga(actions) {
  yield put(loadingStart());
  // const encodedPassword = encode(actions.password); // Encode the password
  var data = JSON.stringify({
    usr: actions.username,
    pwd: actions.password,
  });
  try {
    const response = yield api.post("api/method/login", data);
    yield put(userLoginSuccess(response, actions.submitted));
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* userUpdatePasswordSaga(actions) {
  yield put(loadingStart());
  var data = {
    new_password: actions.new_password,
    key: actions.key,
  };
  try {
    const response = yield api.post(
      "api/method/frappe.core.doctype.user.user.update_password",
      data
    );
    yield put(userUpdatePasswordSuccess(response));
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* userForgotPasswordSaga(actions) {
  yield put(loadingStart());
  var data = {
    email: actions.email,
    domain: actions.domain,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.common.forgot_password",
      data
    );
    yield put(userForgotPasswordSuccess(response));
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getOrgDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {};
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.organization_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getOrgDetailsSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* uiMenuListSaga(actions) {
  yield put(loadingStart());
  var data = { bi_id: actions.bi_id, product_id: actions.product_id };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.common.GetMenuList",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(uiMenuListSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* biDetailsListSaga(actions) {
  yield put(loadingStart());
  var data = {};
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.business_initiative",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(biDetailsListSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* biDetailsGetSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.business_initiative",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(biDetailsGetSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* biDetailsPostSaga(actions) {
  yield put(loadingStart());

  var data = JSON.stringify({
    bi_id: actions.bi_id,
    bi_name: actions.bi_name,
    bi_owner: actions.bi_owner,
    sec_name: actions.sector,
    subsec_name: actions.sub_sector,
    obj: actions.obj,
    desc: actions.desc,
    start_dt: actions.start_dt,
    target_dt: actions.target_dt,
    business_name: actions.business_name,
  });
  try {
    const response = yield api.post(
      "api/method/excelrate.api.onboarding.business_initiative",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(biDetailsPostSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* biSubSectorGetSaga(actions) {
  yield put(loadingStart());
  var data = {
    data: actions.data,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.select_subsector",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(bisubsectorGetSuccess(response, actions.sectorChange));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* biBusinessTypeGetSaga(actions) {
  yield put(loadingStart());

  var data = {
    bi_id: actions.bi_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.select_businesstype",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(bibusinesstypeGetSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* biBusinessTypePostSaga(actions) {
  yield put(loadingStart());

  var data = JSON.stringify({
    bi_id: actions.bi_id,
    bussiness_name: actions.bussiness_name,
  });
  try {
    const response = yield api.post(
      "api/method/excelrate.api.select_businesstype",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(bibusinesstypePostSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* biProductDetailsGetSaga(actions) {
  yield put(loadingStart());

  var data = {
    bi_id: actions.bi_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.customer_product_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(biproductdetailsGetSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* biProductDetailsPostSaga(actions) {
  yield put(loadingStart());
  console.log("actions", actions);
  if (actions.action === "add" || actions.action === "edit") {
    var data = {
      bi_id: actions.bi_id,
      product_name: actions.product_name,
      product_category: actions.product_category,
      desc: actions.desc,
      action: actions.action,
      isactive: actions.is_active,
    };
  } else {
    var data = {
      bi_id: actions.bi_id,
      action: actions.action,
    };
  }

  if (actions.product_id) {
    data.product_id = actions.product_id;
  }

  try {
    const response = yield api.post(
      "api/method/excelrate.api.onboarding.customer_product_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(biproductdetailsPostSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* vcListGetSaga(actions) {
  yield put(loadingStart());

  var data = {
    bi_id: actions.bi_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.select_all_value_chains",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(vcListGetSuccess(response, actions.isVCFetchedAfterAdd));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* vcCreateSaga(actions) {
  yield put(loadingStart());

  var data = {
    data: actions.data,
    bi_id: actions.bi_id,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.onboarding.create_customer_value_chain",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(vcCreateGetSuccess(response, actions.vcAdded));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* vcChangeTableDataSaga(actions) {
  yield put(loadingStart());

  var data = {
    bi_id: actions.bi_id,
    ctg_id: actions.catg_id,
    prod_id: actions.product_id,
    dimension_id: actions.dimension_id,
    is_selected: actions.is_selected,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.onboarding.customer_value_chain_mapping",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(vcChangeTableDataSuccess(response, actions.vcStateChanged));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* removeCustomerVCSelectionSaga(actions) {
  yield put(loadingStart());

  var data = {
    bi_id: actions.bi_id,
    value_chain_id: actions.value_chain_id,
    isactive: actions.isactive,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.onboarding.add_or_delete_value_chain",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(removeCustomerVCSelectionSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* addCustomerVCSelectionSaga(actions) {
  yield put(loadingStart());

  var data = {
    bi_id: actions.bi_id,
    data: actions.data,
    is_selection: actions.is_selection,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.onboarding.add_customer_value_chain",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(addCustomerVCSelectionSuccess(response, actions.vcEdited));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getCustomerVCSelectionSaga(actions) {
  yield put(loadingStart());

  var data = {
    bi_id: actions.bi_id,
    value_name_id: actions.value_name_id,
    vcnamecheck: actions.vcnamecheck,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.select_customer_value_chain",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        getCustomerVCSelectionSuccess(
          response,
          actions.isInitialVCFetched,
          actions.isUpdatedVCFetched,
          actions.vcFetched
        )
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getProductDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.product_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getProductsDetailsSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getAnsoffMatrixDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.customer_ansoff_matrix",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getAnsoffMatrixDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getAnsoffMatrixGridDataSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.onboarding.ansoff_gird_data",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getAnsoffMatrixGridDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postAnsoffMatrixDataSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
    LeftPanelData: actions.leftpanelData,
    gridData: actions.gridData,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.onboarding.customer_ansoff_matrix_choice",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postAnsoffMatrixDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getMarketSizeDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.validation.market_size_detials",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getMarketSizeDetailsSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postMarketSizeDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
    market_size: actions.market_size,
    size: actions.unit,
    unit: actions.currency,
    remarks: actions.remarks,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.validation.market_size_detials",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postMarketSizeDetailsSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getCompetitorDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.validation.customer_competitors",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        getCompetitorDetailsSuccess(response.data, actions.isCompetitorUpdated)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postCompetitorDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
    data: actions.competitor,
    selected_dt: actions.selected_dt,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.validation.customer_competitors",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        postCompetitorDetailsSuccess(
          response,
          actions.isCompetitorAdded,
          actions.isCompetitorSaved,
          actions.isCompetitorDetailsAdded,
          actions.isCompetitorDetailsSaved
        )
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getFeatureTypeDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.validation.customer_criteria",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        getFeatureTypeDetailsSuccess(
          response.data,
          actions.isFeatureDetailsFeched
        )
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getCompAnalDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.validation.customer_competitor_analysis",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        getCompAnalDetailsSuccess(response.data, actions.isCompAnalFetched)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postFeatureDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
    data: actions.data,
    feature_id: actions.featureID,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.validation.customer_criteria",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        postFeatureDetailsSuccess(
          response.data,
          actions.isFeaturePosted,
          actions.featureID
        )
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postCompAnalDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
    data: [
      {
        feature_id: actions.feature_id,
        competitor_id: actions.competitor_id,
        isactive: actions.isactive,
        feature_type_id: actions.feature_type_id,
      },
    ],
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.validation.customer_competitor_analysis",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postCompAnalDetailsSuccess(response));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getMetricsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.validation.customer_metrics",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getMetricsSuccess(response.data, actions.isMetricsFeched));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postMetricsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
    create_data: actions.create_data,
    select_data: actions.select_data,
    delmetric_id: actions.metric_id,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.validation.customer_metrics",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        postMetricsSuccess(
          response.data,
          actions.isMetricsSaved,
          actions.isMetricSelected
        )
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getMSDocsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    screen_id: actions.screen_id,
    main_sc_id: actions.main_screen_id,
    folder_type: actions.folder_type,
    sub_folder_id: actions.sub_folder_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.gdrive.get_drive_total_files",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        getMSDocsSuccess(
          response.data,
          actions.isMSDocsPostDone,
          actions.getScreenName
        )
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postMSDocsSaga(actions) {
  yield put(loadingStart());
  const data = new FormData();
  data.append("bi_id", actions.bi_id);
  data.append("screen_id", actions.screen_id);
  data.append("main_sc_id", actions.main_screen_id);
  data.append("files", actions.doc);
  let progress = 0;

  try {
    const response = yield api.post(
      "api/method/excelrate.gdrive.upload_files",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
        onUploadProgress: function (data) {
          progress = Math.round((100 * data.loaded) / data.total);
          eventEmitter.emit("UPLOAD_PROGRESS", progress);
        },
      }
    );
    if (response.ok) {
      yield put(
        postMSDocsSuccess(
          response.data,
          actions.isMSDocsPosted,
          actions.screen_name
        )
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getNotesSaga(actions) {
  yield put(loadingStart());
  var data = {
    note_id: actions.note_id,
  };
  if (actions.meetingaction) {
    data["action"] = actions.meetingaction;
  }
  if (actions.bi_id) {
    data["bi_id"] = actions.bi_id;
  }
  try {
    const response = yield api.get(
      "api/method/excelrate.api.common.add_notes",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getNotesSuccess(response.data, actions.isActionChanged));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postNotesSaga(actions) {
  // yield put(loadingStart());
  var data = {
    notes_title: actions.notes_title,
    bi_id: actions.bi_id,
    note_id: actions.note_id,
    content: actions.content,
    action: actions.action,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.common.add_notes",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postNotesSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getMeetingSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.common.customer_meeting_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getMeetingSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postMeetingSaga(actions) {
  // yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    data: actions.data,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.common.customer_meeting_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postMeetingSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getKnowledgeBaseSaga(actions) {
  yield put(loadingStart());
  try {
    if (actions.tag) {
      var data = { tag: actions.tag };
    } else {
      data = { screen_id: actions.screen_id };
    }

    const response = yield api.get(
      "api/method/excelrate.api.kb.get_knowledge_base_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getKnowledgeBaseSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postCopyKnowledgeBaseTemplateSaga(actions) {
  // yield put(loadingStart());
  var data = {
    screen_id: actions.screen_id,
    bi_id: actions.bi_id,
    files: actions.files,
    template_id: actions.templateId,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.gdrive.save_knowledge_template",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postCopyKnowledgeBaseTemplateSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getXLExecutionDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
    };
    if (actions.function_id) {
      data["function_id"] = actions.function_id;
    }
    if (actions.task_filter) {
      data["task_filter"] = actions.task_filter;
    }
    const response = yield api.get(
      "api/method/excelrate.api.execution.get_customer_strategy_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getXLExecutionPlanDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

// export function* postCopyMasterFunctionsXLExecutionSaga(actions) {
//   yield put(loadingStart());
//   var data = {
//     bi_id: actions.bi_id,
//     product_id: actions.product_id,
//   };
//   try {
//     const response = yield api.post(
//       "api/method/excelrate.api.readiness.master_strategy_to_customer_strategy",
//       data,
//       {
//         headers: {
//           Authorization: "token " + localStorage.getItem("token"),
//         },
//       }
//     );
//     if (response.ok) {
//     } else if (response.status === 403) {
//       if (response.data.hasOwnProperty("_server_messages")) {
//         yield put(exceptionSessionExpire(response.data.exception));
//       } else if (response.data.hasOwnProperty("_error_message")) {
//         yield put(exceptionAPIError(response.data._error_message));
//       }
//     } else if (response.status === 417) {
//       yield put(
//         exceptionAPIError(
//           JSON.parse(JSON.parse(response.data._server_messages.message))
//         )
//       );
//     } else {
//       yield put(exceptionError(response.data.exception));
//     }
//   } catch (error) {
//     yield put(exceptionError(error.toString()));
//   }
// }

export function* postEditStrategyObjectiveSaga(actions) {
  // yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    obj: actions.obj,
  };
  try {
    const response = yield api.post(
      "api/method/excelrate.api.execution.edit_strategy_objective",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postStrategyGoalsSaga(actions) {
  yield put(loadingStart());

  var data = { data: actions.data };

  try {
    const response = yield api.post(
      "api/method/excelrate.api.execution.add_strategy_goals",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postStrategyGoalSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postStrategyFunctionsSaga(actions) {
  yield put(loadingStart());
  var data = {
    data: actions.data,
    stage_type: actions.stage_type,
    is_function: actions.is_function,
    is_imperative: actions.is_imperative,
    is_milestone: actions.is_milestone,
    is_task: actions.is_task,
    is_subtask: actions.is_subtask,
  };

  try {
    const response = yield api.post(
      "api/method/excelrate.api.execution.add_strategy_function_tasks_subtasks",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postStrategyFunctionsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

// export function* getDepartmentsSaga(actions) {
//   yield put(loadingStart());
//   try {
//     var data = {};

//     const response = yield api.get(
//       "api/method/excelrate.api.execution.get_login_emp_departments",
//       data,
//       {
//         headers: {
//           Authorization: "token " + localStorage.getItem("token"),
//         },
//       }
//     );
//     if (response.ok) {
//       yield put(getDepartmentsSuccess(response.data));
//     } else if (response.status === 403) {
//       if (response.data.hasOwnProperty("_server_messages")) {
//         yield put(exceptionSessionExpire(response.data.exception));
//       } else if (response.data.hasOwnProperty("_error_message")) {
//         yield put(exceptionAPIError(response.data._error_message));
//       }
//     } else if (response.status === 417) {
//       yield put(
//         exceptionAPIError(
//           JSON.parse(JSON.parse(response.data._server_messages.message))
//         )
//       );
//     } else {
//       yield put(exceptionError(response.data.exception));
//     }
//   } catch (error) {
//     yield put(exceptionError(error.toString()));
//   }
// }

export function* getMasterTaskPriorityAndStatusDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      prod_id: actions.product_id,
      function_id: actions.function_id,
    };
    const response = yield api.get(
      "api/method/excelrate.api.execution.get_task_status_and_prioriy",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getMasterTaskPriorityAndStatusDataSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getEmployeeDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {};

    const response = yield api.get(
      "api/method/excelrate.api.readiness.get_emp_details_of_organisation",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getEmployeeDataSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getInitiativeTrackerDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
    };

    const response = yield api.get(
      "api/method/excelrate.api.execution.get_initiative_tracker_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getInitiativeTrackerDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getExistingGoalsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
    };

    const response = yield api.get(
      "api/method/excelrate.api.readiness.get_existed_strategy_goals",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getExistingGoalsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getExistingFunctionsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      is_active: actions.is_active,
    };

    const response = yield api.get(
      "api/method/excelrate.api.readiness.get_existed_strategy_functions",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getExistingFunctionsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getTaskDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      task_id: actions.task_id,
    };
    const response = yield api.get(
      "api/method/excelrate.api.execution.get_single_task_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getTaskDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getFunctionalGoalsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      imperative_id: actions.functional_goal_id,
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      goal_review_id: actions.goal_review_id,
    };
    const response = yield api.get(
      "api/method/excelrate.api.execution.get_imperative_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getFunctionalGoalsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postTaskCommentsSaga(actions) {
  yield put(loadingStart());

  try {
    var data = {
      action: actions.action,
    };
    if (actions.task_id) {
      data["task_id"] = actions.task_id;
    }
    if (actions.content) {
      data["content"] = actions.content;
    }
    if (actions.comment_id) {
      data["comment_id"] = actions.comment_id;
    }

    const response = yield api.post(
      "api/method/excelrate.api.execution.add_tasks_comments",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postTaskCommentsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postFunGoalKeyDecisionsSaga(actions) {
  yield put(loadingStart());
  try {
    if (actions.action === "delete") {
      var data = {
        action: actions.action,
      };
    } else {
      var data = {
        title: actions.title,
        desc: actions.desc,
        due_date: actions.due_date,
        rating: actions.rating,
        action: actions.action,
        assigned_to: actions.assigned_to,
      };
      if (actions.action === "add") {
        data["imperative_id"] = actions.imperative_id;
        data["review_id"] = actions.fungoal_review_id;
      }
    }

    if (actions.action === "edit" || actions.action === "delete") {
      data["key_desc_id"] = actions.key_desc_id;
    }

    const response = yield api.post(
      "api/method/excelrate.api.execution.add_fun_goal_key_desicions",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFunGoalKeyDecisionsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postFunGoalKeyRiskIssuesSaga(actions) {
  yield put(loadingStart());
  try {
    if (actions.action === "delete") {
      var data = {
        action: actions.action,
      };
    } else {
      var data = {
        title: actions.title,
        desc: actions.desc,
        due_date: actions.due_date,
        rating: actions.rating,
        mitigation: actions.mitigation,
        action: actions.action,
        assigned_to: actions.assigned_to,
      };
    }
    if (actions.action === "add") {
      data["imperative_id"] = actions.imperative_id;
      data["review_id"] = actions.fungoal_review_id;
    }
    if (actions.action === "edit" || actions.action === "delete") {
      data["risk_id"] = actions.risk_id;
    }

    const response = yield api.post(
      "api/method/excelrate.api.execution.add_fun_goal_risk_and_issues",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFunGoalKeyRiskIssuesSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postFunGoalDependenciesSaga(actions) {
  yield put(loadingStart());
  try {
    if (actions.action === "delete") {
      var data = {
        action: actions.action,
      };
    } else {
      var data = {
        title: actions.title,
        desc: actions.desc,
        due_date: actions.due_date,
        dep_status: actions.dep_status,
        action: actions.action,
      };
      if (actions.imperative_id) {
        data["imperative_id"] = actions.imperative_id;
        data["review_id"] = actions.fungoal_review_id;
      }
    }

    if (actions.dependency_id) {
      data["dependenc_id"] = actions.dependency_id;
    }

    const response = yield api.post(
      "api/method/excelrate.api.execution.add_fun_goal_dependencies",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFunGoalDependenciesSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postFunctionalGoalReviewMeetingDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      imperative_id: actions.imperative_id,
      data: actions.data,
    };

    const response = yield api.post(
      "api/method/excelrate.api.execution.add_fun_meeting_review_data",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFunctionalGoalReviewMeetingDataSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getFunctionalGoalReviewMeetingDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      imperative_id: actions.imperative_id,
      bi_id: actions.bi_id,
    };

    const response = yield api.get(
      "api/method/excelrate.api.execution.add_fun_meeting_review_data",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getFunctionalGoalReviewMeetingDataSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postFunctionalGoalReviewMeetingProgressDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      action: actions.action,
    };

    if (actions.action === "add") {
      data["imperative_id"] = actions.imperative_id;
      data["review_id"] = actions.review_id;
    } else if (actions.action === "edit" || actions.action === "delete") {
      data["progress_id"] = actions.progress_id;
    }
    if (actions.action === "edit" || actions.action === "add") {
      data["desc"] = actions.desc;
    }
    const response = yield api.post(
      "api/method/excelrate.api.execution.add_fun_goal_progress",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        postFunctionalGoalReviewMeetingProgressDataSuccess(response.data)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postFunctionalGoalReviewMeetingNextStepsDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      action: actions.action,
    };
    if (actions.action === "add") {
      data["imperative_id"] = actions.imperative_id;
      data["review_id"] = actions.review_id;
    } else if (actions.action === "edit" || actions.action === "delete") {
      data["next_step_id"] = actions.next_step_id;
    }
    if (actions.action === "edit" || actions.action === "add") {
      data["desc"] = actions.desc;
    }

    const response = yield api.post(
      "api/method/excelrate.api.execution.add_fun_goal_next_steps",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFunctionalGoalReviewMeetingNextStepsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getdashboardStateManagementSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {};

    const response = yield api.get(
      "api/method/excelrate.api.common.dashboard_state_management",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getdashboardStateManagementSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postdashboardStateManagementSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      last_screen: actions.last_screen,
      is_completed: actions.is_completed,
      is_pending: actions.is_pending,
    };

    const response = yield api.post(
      "api/method/excelrate.api.common.dashboard_state_management",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postdashboardStateManagementSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getExecutionReadinessFunctionSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
    };

    const response = yield api.get(
      "api/method/excelrate.api.readiness.execution_readiness_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getExecutionReadinessDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* getStrategyReadinessFunctionSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
    };
    const response = yield api.get(
      "api/method/excelrate.api.readiness.strategy_readiness_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getStrategyReadinessDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getCalendarMeetingDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      function_id: actions.function_id,
      function_area_id: actions.function_area_id,
    };
    const response = yield api.get(
      "api/method/excelrate.api.common.calendar_meeting_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getCalenderMeetingDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getDashboardTaskDataSaga() {
  yield put(loadingStart());
  try {
    var data = {};
    const response = yield api.get(
      "api/method/excelrate.api.common.dashboard_task_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getDashboardTaskDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getEcosystemMappingSectionDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
    };

    const response = yield api.get(
      "api/method/excelrate.api.onboarding.eco_system_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getEcosystemMappingSectionDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postEcosystemMappingSectionDataSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      action: actions.action,
      isactive: actions.is_active,
    };
    if (actions.action === "add") {
      data["bi_id"] = actions.bi_id;
      data["sub_section_id"] = actions.sub_section_id;
      data["name"] = actions.name;
    } else if (actions.action === "edit" || actions.action === "delete") {
      data["option_id"] = actions.sub_section_child_id;
      data["name"] = actions.name;
    }
    const response = yield api.post(
      "api/method/excelrate.api.onboarding.eco_system_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postEcosystemMappingSectionDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getExecutionReadinessFunctionalAreaDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      function_id: actions.function_id,
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      main_screen_id: actions.main_screen_id,
      screen_id: actions.screen_id,
    };
    const response = yield api.get(
      "api/method/excelrate.api.readiness.execution_readiness_question_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        getExecutionReadinessFunctionalAreaDetailsSuccess(response.data)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

// export function* postExecutionReadinessFunctionalAreaDetailsSaga(actions) {
//   yield put(loadingStart());

//   try {
//     var data = {
//       function_id: actions.function_id,
//       function_area_id: actions.function_area_id,
//       question_id: actions.question_id,
//       answer_id: actions.answer_id,
//       is_master: actions.is_master,
//     };
//     const response = yield api.post(
//       "api/method/excelrate.api.readiness.execution_readiness_customer_answer_choice",
//       data,
//       {
//         headers: {
//           Authorization: "token " + localStorage.getItem("token"),
//         },
//       }
//     );
//     if (response.ok) {
//       yield put(
//         postExecutionReadinessFunctionalAreaDetailsSuccess(response.data)
//       );
//     } else if (response.status === 403) {
//       if (response.data.hasOwnProperty("_server_messages")) {
//         yield put(exceptionSessionExpire(response.data.exception));
//       } else if (response.data.hasOwnProperty("_error_message")) {
//         yield put(exceptionAPIError(response.data._error_message));
//       }
//     } else if (response.status === 417) {
//       yield put(
//         exceptionAPIError(
//           JSON.parse(JSON.parse(response.data._server_messages.message))
//         )
//       );
//     } else {
//       yield put(exceptionError(response.data.exception));
//     }
//   } catch (error) {
//     yield put(exceptionError(error.toString()));
//   }
// }

export function* getNoticationsDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = { isactive: true };
    const response = yield api.get(
      "api/method/excelrate.api.common.get_notification_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getNoticationsDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postNoticationsDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    if (actions.notif_id) {
      var data = { notif_id: actions.notif_id };
    } else {
      var data = { action: "InactiveAll" };
    }

    const response = yield api.post(
      "api/method/excelrate.api.common.get_notification_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postNoticationsDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export async function getHelpInfo(documentName, helpKey) {
  const data = {
    documentType: documentName,
    info: helpKey,
  };
  const response = await api.get(
    "api/method/excelrate.helpAPI.get_helpInfo",
    data,
    {
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
    }
  );
  if (response) {
    return response.data;
  }
}

export function* getFileManagerDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
    };

    const response = yield api.get(
      "api/method/excelrate.api.get_filemanager_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getFileManagerDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postFilemanagerDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      action: actions.action,
      isactive: actions.is_active,
    };
    if (actions.action === "add") {
      data["bi_id"] = actions.bi_id;
    } else if (actions.action === "edit" || actions.action === "delete") {
      data["name"] = actions.name;
    }
    const response = yield api.post(
      "api/method/excelrate.api.get_filemanager_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFileManagerDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postExecutionReadinessDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = actions.data;

    const response = yield api.post(
      "api/method/excelrate.api.readiness.execution_readiness_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postExecutionReadinessDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* getExecutionReadinessReviewDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
    };

    const response = yield api.get(
      "api/method/excelrate.api.readiness.execution_readiness_review_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getExectionReadinessReviewDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* postExecutionReadinessCustomerInputDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      question_id: actions.question_id,
      vertical_id: actions.vertical_id,
      action: actions.action,
      name: actions.name,
      input_id: actions.input_id,
      ans_type: actions.ans_type,
    };

    const response = yield api.post(
      "api/method/excelrate.api.readiness.execution_readiness_customer_input_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        postExecutionReadinessCustomerInputDetailsSuccess(response.data)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postExecutionReadinessCustomerAnswerChoiceDetailsSaga(
  actions
) {
  yield put(loadingStart());
  try {
    var data = {
      function_id: actions.function_id,
      question_id: actions.question_id,
      answer_id: actions.answer_id,
      is_master: actions.is_master,
      function_area_id: actions.function_area_id,
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      option_title: actions.option_title,
    };

    const response = yield api.post(
      "api/method/excelrate.api.readiness.execution_readiness_customer_answer_choice",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        postExecutionReadinessCustomerAnswerChoiceDetailsSuccess(response.data)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* postNewStrategyReadinessFunctionsSaga(actions) {
  yield put(loadingStart());
  var data = {
    data: actions.data,
    stage_type: actions.stage_type,
  };

  try {
    const response = yield api.post(
      "api/method/excelrate.api.readiness.strategy_readiness_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postNewStrategyReadinessFunctionsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* getStrategyReadinessExistedFunctionsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      is_active: actions.is_active,
    };

    const response = yield api.get(
      "api/method/excelrate.api.readiness.get_existed_strategy_readiness_functions",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        getStrategyReadinessExistedFunctionsDetailsSuccess(response.data)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

// export function* postStrategyReadinessFunctionalAreaDetailsSaga(actions) {
//   yield put(loadingStart());
//   try {
//     var data = {
//       function_id: actions.function_id,
//       function_area_id: actions.function_area_id,
//       question_id: actions.question_id,
//       answer_id: actions.answer_id,
//       is_master: actions.is_master,
//     };
//     const response = yield api.post(
//       "api/method/excelrate.api.readiness.strategy_readiness_customer_answer_choice",
//       data,
//       {
//         headers: {
//           Authorization: "token " + localStorage.getItem("token"),
//         },
//       }
//     );
//     if (response.ok) {
//       yield put(
//         postStrategyReadinessFunctionalAreaDetailsSuccess(response.data)
//       );
//     } else if (response.status === 403) {
//       if (response.data.hasOwnProperty("_server_messages")) {
//         yield put(exceptionSessionExpire(response.data.exception));
//       } else if (response.data.hasOwnProperty("_error_message")) {
//         yield put(exceptionAPIError(response.data._error_message));
//       }
//     } else if (response.status === 417) {
//       yield put(
//         exceptionAPIError(
//           JSON.parse(JSON.parse(response.data._server_messages.message))
//         )
//       );
//     } else {
//       yield put(exceptionError(response.data.exception));
//     }
//   } catch (error) {
//     yield put(exceptionError(error.toString()));
//   }
// }

export function* getStrategyReadinessFunctionalAreaDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      function_id: actions.function_id,
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      main_screen_id: actions.main_screen_id,
      screen_id: actions.screen_id,
    };
    const response = yield api.get(
      "api/method/excelrate.api.readiness.strategy_readiness_question_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        getStrategyReadinessFunctionalAreaDetailsSuccess(response.data)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* postStrategyReadinessDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = actions.data;
    const response = yield api.post(
      "api/method/excelrate.api.readiness.strategy_readiness_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postStrategyReadinessDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* getStrategyReadinessReviewDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
    };

    const response = yield api.get(
      "api/method/excelrate.api.strategy_readiness_review_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getStrategyReadinessReviewDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postStrategyReadinessCustomerInputDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      question_id: actions.question_id,
      vertical_id: actions.vertical_id,
      action: actions.action,
      name: actions.name,
      input_id: actions.input_id,
      ans_type: actions.ans_type,
    };

    const response = yield api.post(
      "api/method/excelrate.api.readiness.strategy_readiness_customer_input_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        postStrategyReadinessCustomerInputDetailsSuccess(response.data)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postStrategyReadinessCustomerAnswerChoiceDetailsSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      function_id: actions.function_id,
      question_id: actions.question_id,
      answer_id: actions.answer_id,
      is_master: actions.is_master,
      function_area_id: actions.function_area_id,
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      option_title: actions.option_title,
    };

    const response = yield api.post(
      "api/method/excelrate.api.readiness.strategy_readiness_customer_answer_choice",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(
        postStrategyReadinessCustomerAnswerChoiceDetailsSuccess(response.data)
      );
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* postFloderManagerAddNewSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      parent_folder_id: actions.parent_folder_id,
      folder_name: actions.folder_name,
    };
    const response = yield api.post(
      "api/method/excelrate.gdrive.create_sub_folders",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFolderManagerAddNewSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* postFloderManagerEditSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      folder_id: actions.folder_id,
      folder_name: actions.folder_name,
    };
    const response = yield api.post(
      "api/method/excelrate.gdrive.edit_drive_folders",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFolderManagerEditSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* postFloderManagerDeleteSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      folder_id: actions.folder_id,
    };
    const response = yield api.post(
      "api/method/excelrate.gdrive.delete_drive_folders",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postFolderManagerDeleteSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* postUploadFileInSpecificFolderSaga(actions) {
  yield put(loadingStart());
  const data = new FormData();
  data.append("parent_folder_id", actions.parent_folder_id);
  data.append("file", actions.file);
  let progress = 0;

  try {
    const response = yield api.post(
      "api/method/excelrate.gdrive.upload_file_in_specific_folder",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
        onUploadProgress: function (data) {
          progress = Math.round((100 * data.loaded) / data.total);
          eventEmitter.emit("UPLOAD_PROGRESS", progress);
        },
      }
    );
    if (response.ok) {
      yield put(postUploadFileInSpecificFolderSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getDriveFilesSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    screen_id: actions.screen_id,
    main_sc_id: actions.main_screen_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.gdrive.get_drive_files",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getDriveFilesSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* getProjectSummaryDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
  };
  if (actions.product_id && actions.gantt_chart) {
    data.product_id = actions.product_id;
    data.gantt_chart = actions.gantt_chart;
  }
  try {
    const response = yield api.get(
      "api/method/excelrate.api.reports.get_project_summary_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getProjectSummaryDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postLocalFolderManagerSaga(actions) {
  // yield put(loadingStart());
  const data = new FormData();
  data.append("bi_id", actions.bi_id);
  data.append("screen_id", actions.screen_id);
  data.append("main_sc_id", actions.main_screen_id);
  data.append("files", actions.file);
  data.append("question_id", actions.question_id);
  data.append("function_id", actions.function_id);
  data.append("product_id", actions.product_id);
  data.append("task_id", actions.task_id);
  // let progress = 0;

  try {
    const response = yield api.post(
      "api/method/excelrate.api.common.local_folder_manager_upload_files",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
        // onUploadProgress: function (data) {
        //   progress = Math.round((100 * data.loaded) / data.total);
        //   eventEmitter.emit("UPLOAD_PROGRESS", progress);
        // },
      }
    );
    if (response.ok) {
      yield put(postLocalFolderManagerSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getLocalFolderManagerSaga(actions) {
  // yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    screen_id: actions.screen_id,
    main_sc_id: actions.main_screen_id,
    global_files: actions.global_files,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.common.get_local_folder_manager_files",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getLocalFolderManagerSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postLocalFolderManagerFileDeleteSaga(actions) {
  yield put(loadingStart());
  try {
    var data = {
      folder_id: actions.folder_id,
    };
    const response = yield api.post(
      "api/method/excelrate.api.common.local_folder_manager_delete_files",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postLocalFolderManagerFileDeleteSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postScreenCommentsSaga(actions) {
  yield put(loadingStart());

  try {
    var data = {
      action: actions.action,
      bi_id: actions.bi_id,
      main_screen_id: actions.main_screen_id,
      screen_id: actions.screen_id,
    };
    if (actions.content) {
      data["content"] = actions.content;
    }
    if (actions.comment_id) {
      data["comment_id"] = actions.comment_id;
    }
    const response = yield api.post(
      "api/method/excelrate.api.common.add_screen_comments",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postScreenCommentsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getScreenCommentsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    screen_id: actions.screen_id,
    main_screen_id: actions.main_screen_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.common.add_screen_comments",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getScreenCommentsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getBiFGoalStatusSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
    fun_goal_id: actions.fun_goal_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.execution.bi_fun_goal_status",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getBiFGoalStatusSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postBiFGoalStatusSaga(actions) {
  yield put(loadingStart());

  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      fun_goal_id: actions.fun_goal_id,
      data: actions.data,
    };
    const response = yield api.post(
      "api/method/excelrate.api.execution.bi_fun_goal_status",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postBiFGoalStatusSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
export function* getGetGlobalSearchFilterSaga(actions) {
  yield put(loadingStart());
  var data = {};
  try {
    const response = yield api.get(
      "api/method/excelrate.api.common.get_global_search_filter",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getGlobalSearchFilterSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getIsMaintenanceModeSaga(actions) {
  yield put(loadingStart());
  var data = {};
  try {
    const response = yield api.get(
      "api/method/excelrate.api.common.get_server_maintenance_mode",
      data
    );
    if (response.ok) {
      yield put(getIsMaintenanceModeSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getXLStrategyPlanDetailsSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.execution.xlstrategyplan_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getXLStrategyPlanDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* postXLStrategyPlanDetailsSaga(actions) {
  yield put(loadingStart());

  try {
    var data = {
      bi_id: actions.bi_id,
      product_id: actions.product_id,
      data: actions.data,
    };
    const response = yield api.post(
      "api/method/excelrate.api.execution.xlstrategyplan_details",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(postXLStrategyPlanDetailsSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}

export function* getExportWorkboardCsvfileSaga(actions) {
  yield put(loadingStart());
  var data = {
    bi_id: actions.bi_id,
    product_id: actions.product_id,
    tool_name: actions.tool_name,
  };
  try {
    const response = yield api.get(
      "api/method/excelrate.api.execution.export_workboard_tasks_csv_file",
      data,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("token"),
        },
      }
    );
    if (response.ok) {
      yield put(getExportWorkboardCsvfileSuccess(response.data));
    } else if (response.status === 403) {
      if (response.data.hasOwnProperty("_server_messages")) {
        yield put(exceptionSessionExpire(response.data.exception));
      } else if (response.data.hasOwnProperty("_error_message")) {
        yield put(exceptionAPIError(response.data._error_message));
      }
    } else if (response.status === 417) {
      yield put(
        exceptionAPIError(
          JSON.parse(JSON.parse(response.data._server_messages.message))
        )
      );
    } else {
      yield put(exceptionError(response.data.exception));
    }
  } catch (error) {
    yield put(exceptionError(error.toString()));
  }
}
