import React from "react";
import MainComponent from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import keys from "../../utils/helpInfoKeys.json";
import UploadFiles from "../UploadFiles";
import { Button } from "react-bootstrap";
import Login from "../Login";
import CommentSection from "../../utils/CommentSection";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";

const OperatingPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentDocURL, setCurrentDocURL] = useState("");
  const template_url = useSelector((state) => state.template_url);
  const isURLAdded = useSelector((state) => state.isURLAdded);
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const handleBack = () => {
    navigate("/competitors", { replace: true });
  };
  const selectedPermission = useSelector((state) => state.selectedPermission);
  useEffect(() => {
    if (isURLAdded) {
      setCurrentDocURL(template_url);
    }
  }, [isURLAdded]);

  const handleClick = () => {
    navigate("/metrics", { replace: true });
  };
  const handleTemplateButtonClick = () => {
    setTimeout(() => {
      navigate("/kb/templates", { replace: true });
    }, 500);
    dispatch({ type: "SET_TEMPLATE_BTN_SELECTED", value: true });
  };

  const content = (
    <div>
      {/* {currentDocURL && (
        <>
          <a
            style={{
              float: "right",
              marginBottom: 5,
              textDecoration: "none",
              border: 0,
              padding: 5,
              borderRadius: 10,
              background: "#0A5F59",
              color: "white",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            target="_blank"
            href={currentDocURL}
          >
            Popout
          </a>
          <iframe height="100%" width="100%" src={currentDocURL} />
        </>
      )} */}
      <div
        style={{
          marginRight: "20px",
          marginBottom: 0,
          fontSize: "var(--main-heading)",
          fontWeight: "var(--heading-font-weight)",
        }}
      >
        Please upload operating plan or financial plan related documents.
      </div>
      <br />
      <div style={{ marginLeft: "0px" }} className="operating-upload-container">
        <UploadFiles selectedSubMenuPermission={selectedPermission} />
      </div>
      <div>
        <br />
        <div
          style={{
            marginBottom: "10px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Need a Template ?
        </div>
        <Button
          type="button"
          onClick={(e) => handleTemplateButtonClick()}
          style={{
            cursor: "pointer",
            fontSize: "var(--sub-heading-font-size)",
            backgroundColor: "var(--button-primary-color)",
            padding: "4px 10px",
            border: "1px solid var(--rs-border-primary)",
          }}
        >
          Templates
        </Button>
      </div>
      <CommentSection />
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
    </div>
  );
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        handleNext={handleClick}
        handleBackButton={handleBack}
        breadcrumbParent={"Strategy Validation"}
        breadcrumbChild={"Operating Plan"}
        documentName={"Operating Plan"}
        helpKey={keys["Operating Plan"].Name}
        image={"question"}
      />
    );
  }
};

export default OperatingPlan;
