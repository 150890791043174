
export const getApiEndpoint = async () => {
  let apiEndPoint = "";
  const url = new URL(window.location.href);
  const protocol = url.protocol;
  const hostname = url.hostname;
  const port = url.port ? `:${url.port}` : "";
  const clientUrl = port ? `${hostname}${port}` : hostname;

  try {
    // Fetch the sites.json file from the public directory
    const response = await fetch('/sites.json');
    if (!response.ok) {
      throw new Error('Failed to load site configuration.');
    }
    const data = await response.json();
    
    // Find the matching site
    const site = data.sites.find((site) => site.client === clientUrl);
    if (site) {
      apiEndPoint = `${protocol}//${site.backend}`;
    }
  } catch (error) {
    console.error("Error loading API endpoint configuration:", error);
  }
  console.log('api',apiEndPoint)
  return apiEndPoint;
};

export const EmployeePortal = async () => {
  let apiEmpPortalurl = "";
  const url = new URL(window.location.href);
  const protocol = url.protocol;
  const hostname = url.hostname;
  const port = url.port ? `:${url.port}` : "";
  const clientUrl = port ? `${hostname}${port}` : hostname;

  try {
    // Fetch the sites.json file from the public directory
    const response = await fetch('/sites.json');
    if (!response.ok) {
      throw new Error('Failed to load site configuration.');
    }
    const data = await response.json();

    // Find the matching site for the Employee Portal
    const site = data.sites.find((site) => site.client === clientUrl);
    if (site) {
      apiEmpPortalurl = `${protocol}//${site.client}`;
    }
  } catch (error) {
    console.error("Error loading Employee Portal configuration:", error);
  }
  
  console.log('Employee Portal URL:', apiEmpPortalurl);
  return apiEmpPortalurl;
};