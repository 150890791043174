import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import "./MainComponent.css";
import Meeting from "./Meeting";
import { getCalenderMeetingDetails } from "../store/actions";
import { useNavigate } from "react-router-dom";
import NewMeeting from "./NewMeeting";
import Alert from "./Alert";

const Calendar = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMeetingPopUp, setShowMeetingPopUp] = useState(false);
  const [showNewMeetingPopUp, setShowNewMeetingPopUp] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const fetchCalenderMeetingData = useSelector(
    (state) => state.fetchCalenderMeetingData
  );
  const [events, setEvents] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState("");

  useEffect(() => {
    if (localStorage.getItem("bi_id")) {
      dispatch(getCalenderMeetingDetails(localStorage.getItem("bi_id")));
    } else {
      dispatch(getCalenderMeetingDetails());
    }
  }, []);

  useEffect(() => {
    if (
      fetchCalenderMeetingData &&
      fetchCalenderMeetingData.message &&
      fetchCalenderMeetingData.message.length > 0
    ) {
      setEvents(fetchCalenderMeetingData.message);
    }
  }, [fetchCalenderMeetingData]);

  const handleCloseMeetingPopup = () => {
    onClose();
    setShowMeetingPopUp(false);
  };

  const handleMeetingClick = (arg) => {
    if (arg.extendedProps.type === "General") {
      setSelectedMeeting(arg.extendedProps.meeting_id);
      setShowMeetingPopUp(true);
    } else if (arg.extendedProps.type === "Functioanl Goal") {
      setSelectedMeeting(arg.extendedProps.meeting_id);
      localStorage.setItem("bi_id", arg.extendedProps.bi_id);
      localStorage.setItem("product_id", arg.extendedProps.product_id);
      localStorage.setItem(
        "functional_goal_id",
        arg.extendedProps.imperative_id
      );
      localStorage.setItem("goal_review_id", arg.extendedProps.goal_review_id);
      navigate("/bireview");
      window.location.reload();
    } else {
      console.log("undefined");
    }
  };
  const handleDateClick = (info) => {
    setEventDate(info);
    setShowNewMeetingPopUp(true);
  };
  return (
    <div>
      <div className="modal-overlay">
        <div
          className="modal-content"
          style={{
            padding: "20px",
            minWidth: "1200px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                minWidth: "1100px",
              }}
            >
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={"dayGridMonth"}
                events={events}
                eventClick={(info) => handleMeetingClick(info.event)}
                headerToolbar={{
                  start: "today prev,next",
                  center: "title",
                  end: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                height={"85vh"}
                dayMaxEventRows={true}
                dateClick={(info) => {
                  const selectedDate = new Date(info.date);
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);
                  if (selectedDate >= today) {
                    handleDateClick(info.date);
                  } else {
                    Alert.warning({
                      title: "Creating meetings for past dates is not allowed.",
                      text: "",
                      icon: "warning",
                      showConfirmButton: true,
                    });
                  }
                }}
              />
            </div>
            <AiOutlineClose
              size={24}
              onClick={handleCloseMeetingPopup}
              style={{ cursor: "pointer", marginTop: "5px" }}
            />
            {showMeetingPopUp && (
              <Meeting
                onClose={() => handleCloseMeetingPopup()}
                selectedMeeting={selectedMeeting}
              />
            )}
            {showNewMeetingPopUp && (
              <NewMeeting
                onClose={() => handleCloseMeetingPopup()}
                selectedMeeting={selectedMeeting}
                eventDate={eventDate}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
