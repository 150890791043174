import React, { useEffect, useState } from "react";
import Category from "./Category";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";
import { FINAL_DATA, SALES_FINAL_DATA } from "./Data";
import MainComponent from "../MainComponent";
import { useNavigate } from "react-router-dom";
import keys from "../../utils/helpInfoKeys.json";
import Login from "../Login";
import SideNavHelpInfo from "../../utils/SideNavHelpInfo";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allQuestionsList = useSelector((state) => state.allQuestionsList);
  const [categoryList, setCategoryList] = useState([]);
  const [functionName, setFunctionName] = useState("");
  const fetchExecutionReadinessFunAreaData = useSelector(
    (state) => state.fetchExecutionReadinessFunAreaData
  );
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  const fetchpostExecutionReadinessCustomerInputDetails = useSelector(
    (state) => state.fetchpostExecutionReadinessCustomerInputDetails
  );
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (
      fetchpostExecutionReadinessCustomerInputDetails &&
      (fetchpostExecutionReadinessCustomerInputDetails.action === "add" ||
        fetchpostExecutionReadinessCustomerInputDetails.action === "delete")
    ) {
      window.location.reload();
    }
  }, [fetchpostExecutionReadinessCustomerInputDetails]);

  useEffect(() => {
    dispatch(
      Actions.getExecutionReadinessFunctionalAreaDetails(
        localStorage.getItem("function_id"),
        localStorage.getItem("bi_id"),
        localStorage.getItem("product_id"),
        localStorage.getItem('main_screen_id'),
        localStorage.getItem('screen_id')
      )
    );
  }, []);

  useEffect(() => {
    if (
      fetchExecutionReadinessFunAreaData &&
      fetchExecutionReadinessFunAreaData.functions &&
      fetchExecutionReadinessFunAreaData.functions.length > 0
    ) {
      setFunctionName(fetchExecutionReadinessFunAreaData.functions[0].name);
      dispatch(
        Actions.setAllQuestions(
          fetchExecutionReadinessFunAreaData.functions[0].sections
        )
      );
    }
    //dispatch(Actions.setAllQuestions(FINAL_DATA));
    // dispatch(Actions.setAllQuestions(SALES_FINAL_DATA));
  }, [fetchExecutionReadinessFunAreaData]);

  useEffect(() => {
    setCategoryList(allQuestionsList);
  }, [allQuestionsList]);
  const handleBack = () => {
    navigate("/executionreadiness", { replace: true });
  };

  const content = (
    <>
      <div
        className="App"
        style={{ textAlign: "left", fontFamily: "Source Sans Pro" }}
      >
        <div
          style={{
            fontSize: "var(--main-heading)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          {functionName}
        </div>
        <br />
        <Category category={categoryList} />
        {selectedHelpInfoSidebar && <SideNavHelpInfo />}
      </div>
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <MainComponent
        maincontent={content}
        showSelector={true}
        showButtons={true}
        showProducts={true}
        breadcrumbShowGt={true}
        showSubGt={true}
        handleBackButton={handleBack}
        NextButtonHide={true}
        breadcrumbParent={"Enterprise Readiness"}
        breadcrumbSubChild={functionName}
        breadcrumbChild={"Execution Readiness"}
        breadcrumbChildLink={"/executionreadiness"}
        documentName={"Execution Readiness Function"}
        helpKey={keys["Execution Readiness Function"].Name}
        image={"question"}
      />
    );
  }
}
