import React, { useState, useEffect } from "react";
import { getIsMaintenanceMode, userLogin } from "../store/actions/index.js";
import { useSelector, useDispatch } from "react-redux";
import Image from "../assets/images/XL.png";
import { Form, Button } from "react-bootstrap";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader.js";
import Cookies from "js-cookie"; // Import the js-cookie library
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import Logout from "./Logout.js";
import MaintenancePage from "../../standardpages/MaintenancePage.js";

function Login() {
  const fetchGetMaintenanceMode = useSelector(
    (state) => state.fetchGetMaintenanceMode
  );
  const isMaintenanceMode = useSelector((state) => state.isMaintenanceMode);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const isLoggedin = useSelector((state) => state.isLoginFetched);
  const isSubmitted = useSelector((state) => state.submitted);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showError, setShowError] = useState(false);
  const response = useSelector((state) => JSON.stringify(state.userDetails));
  // const showLoader = useSelector((state) => state.showLoader);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const lastLoginUsername = Cookies.get("lastLoginUsername", {
      secure: true,
      sameSite: "None",
    });
    if (lastLoginUsername) {
      setUsername(lastLoginUsername);
    }
  }, []);

  useEffect(() => {
    if (isLoggedin) {
      var response = JSON.parse(localStorage.getItem("userDetails"));
      if (response.ok) {
        if (response) {
          if (response.data["message"] === "Logged In") {
            localStorage.setItem(
              "token",
              response.data["api_key"] + ":" + response.data["api_secret"]
            );
            localStorage.setItem("authenticated", true);
            localStorage.setItem("full_name", response.data["full_name"]);
            // Cookies.set("sid", response.data["sid"]);
            navigate("/dashboard");
          } else {
            setShowLoader(false);
            setShowError(true);
          }
        }
      } else if ((response.status = 403)) {
        setShowLoader(false);
        setShowError(true);
      } else {
        setShowLoader(false);
        setShowError(true);
      }
    }
  }, [isLoggedin, isSubmitted]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoader(true);
    setShowError(false);
    setSubmitted(!submitted);
    dispatch(userLogin(username, password, submitted));
    Cookies.set("lastLoginUsername", username);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    dispatch(getIsMaintenanceMode());
  }, [dispatch]);
  const [showurls, setshowurls] = useState(false);

  useEffect(() => {
    if (fetchGetMaintenanceMode && fetchGetMaintenanceMode.status === 200) {
      dispatch({
        type: "IS_MAINTENANCE_MODE",
        value: fetchGetMaintenanceMode.is_maintenance === 1,
      });
      setshowurls(true);
    }
  }, [fetchGetMaintenanceMode, dispatch]);

  if (isMaintenanceMode) {
    return (
      <>
        <Logout />
        <MaintenancePage />
      </>
    );
  }
  return (
    <>
      {showLoader && <Loader />}
      {showurls && (
        <div
          className="pagecenter loginForm"
          style={{ padding: "10%", width: "100%" }}
        >
          <Form
            className="modal-content animate"
            onSubmit={handleSubmit}
            style={{ width: "500px", maxWidth: "500px" }}
          >
            <div
              className="container1"
              style={{ width: "90%", paddingBottom: "5%", paddingLeft: "10%" }}
            >
              <h3 style={{ textAlignVertical: "center", textAlign: "center" }}>
                <img style={{ width: "7%" }} src={Image} />
                Excelrate
              </h3>
              <Form.Group size="lg" controlId="text">
                <Form.Control
                  autoFocus
                  name="username"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  placeholder="Enter Username"
                  autoComplete="off"
                />
              </Form.Group>

              <Form.Group size="lg" controlId="password">
                <Form.Control
                  name="password"
                  style={{ position: "relative" }}
                  type={passwordVisible ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  placeholder="Enter Password"
                />
                <span
                  className="password-toggle-login"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </span>
              </Form.Group>
              {showError && (
                <span style={{ color: "red" }}>Invalid Username/Password</span>
              )}
              <Button size="lg" type="submit" style={{ height: "50px" }}>
                Login
              </Button>
              <div className="forgot-password-login">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
}

export default Login;
