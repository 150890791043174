import {
  LOADING_START,
  LOGIN_SUCCESS,
  EXCEPTION_ERROR,
  RESET_EXCEPTION_ERROR,
  USER_LOGIN_INITIATE,
  GET_ORG_DETAILS,
  GET_ORG_DETAILS_SUCCESS,
  UI_MENU_LIST,
  UI_MENU_LIST_SUCCESS,
  BI_DETAILS,
  BI_DETAILS_LIST,
  BI_DETAILS_GET_SUCCESS,
  BI_DETAILS_POST_SUCCESS,
  BI_DETAILS_LIST_SUCCESS,
  BI_DETAILS_SUCCESS,
  BI_DETAILS_POST,
  BI_SECTOR_GET,
  BI_SECTOR_POST,
  BI_SECTOR_GET_SUCCESS,
  BI_SECTOR_POST_SUCCESS,
  BI_SUB_SECTOR_GET,
  BI_SUB_SECTOR_POST,
  BI_SUB_SECTOR_GET_SUCCESS,
  BI_SUB_SECTOR_POST_SUCCESS,
  BI_BUSINESS_TYPE_GET,
  BI_BUSINESS_TYPE_POST,
  BI_BUSINESS_TYPE_GET_SUCCESS,
  BI_BUSINESS_TYPE_POST_SUCCESS,
  BI_PRODUCT_DETAILS_GET,
  BI_PRODUCT_DETAILS_GET_SUCCESS,
  BI_PRODUCT_DETAILS_POST,
  BI_PRODUCT_DETAILS_POST_SUCCESS,
  VC_LIST_GET,
  VC_LIST_GET_SUCCESS,
  VC_SELECTED_GET,
  VC_SELECTED_GET_SUCCESS,
  VC_CREATE,
  VC_CREATE_GET_SUCCESS,
  VC_CHANGE_TABLE_DATA,
  VC_CHANGE_TABLE_DATA_SUCCESS,
  ADD_CUSTOMER_VC_SELECTION,
  ADD_CUSTOMER_VC_SELECTION_SUCCESS,
  GET_CUSTOMER_VC_SELECTION,
  GET_CUSTOMER_VC_SELECTION_SUCCESS,
  EDIT_DIMENSIONS,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_DIMENSION,
  ADD_DIMENSION_SUCCESS,
  ADD_CHILD_DIMENSION,
  ADD_CHILD_DIMENSION_SUCCESS,
  SESSION_EXPIRE_ERROR,
  SESSION_API_ERROR,
  REMOVE_CUSTOMER_VC_SELECTION,
  REMOVE_CUSTOMER_VC_SELECTION_SUCCESS,
  ADD_CURRENT_DOC_URL,
  GET_PRODUCTS_DETAILS,
  GET_PRODUCTS_DETAILS_SUCCESS,
  GET_MARKET_SIZE_DETAILS,
  GET_MARKET_SIZE_DETAILS_SUCCESS,
  GET_COMPETITOR_ANALYSIS_DETAILS,
  GET_COMPETITOR_ANALYSIS_DETAILS_SUCCESS,
  GET_COMPETITOR_DETAILS,
  GET_COMPETITOR_DETAILS_SUCCESS,
  GET_FETAURE_TYPE_DETAILS,
  GET_FETAURE_TYPE_DETAILS_SUCCESS,
  POST_COMPETITOR_ANALYSIS_DETAILS,
  POST_COMPETITOR_ANALYSIS_DETAILS_SUCCESS,
  POST_COMPETITOR_DETAILS,
  POST_COMPETITOR_DETAILS_SUCCESS,
  POST_FETAURE_DETAILS_SUCCESS,
  POST_FETAURE_DETAILS,
  POST_MARKET_SIZE_DETAILS,
  POST_MARKET_SIZE_DETAILS_SUCCESS,
  GET_METRICS,
  GET_METRICS_SUCCESS,
  POST_METRICS,
  POST_METRICS_SUCCESS,
  POST_MS_DOCS,
  POST_MS_DOCS_SUCCESS,
  GET_MS_DOCS,
  GET_MS_DOCS_SUCCESS,
  ADD_SCREEN_ID,
  ADD_MAIN_SCREEN_ID,
  GET_NOTES,
  GET_NOTES_SUCCESS,
  POST_NOTES,
  POST_NOTES_SUCCESS,
  GET_ANSOFF_MATRIX_DETAILS,
  GET_ANSOFF_MATRIX_DETAILS_SUCCESS,
  GET_MEETING,
  GET_MEETING_SUCCESS,
  POST_MEETING,
  POST_MEETING_SUCCESS,
  GET_KNOWLEDGE_BASE,
  GET_KNOWLEDGE_BASE_SUCCESS,
  POST_COPY_KNOWLEDGE_BASE,
  POST_COPY_KNOWLEDGE_BASE_SUCCESS,
  POST_COPY_MASTER_FUNCTIONS_XLEXECUTION,
  GET_XLEXECUTION_PLAN_DETAILS,
  GET_XLEXECUTION_PLAN_DETAILS_SUCCESS,
  POST_EDIT_STRATEGY_OBJECTIVE,
  POST_STRATEGY_GOALS,
  POST_STRATEGY_FUNCTIONS,
  POST_STRATEGY_FUNCTIONS_SUCCESS,
  POST_STRATEGY_GOAL_SUCCESS,
  GET_CHAT_DETAILS_INFO,
  // GET_DEPARTMENTS,
  GET_EMPLOYEE_DATA,
  GET_TASKS_PRIORITY_STATUS,
  GET_INITIATIVE_TRACKER_DETAILS,
  // GET_DEPARTMENTS_SUCCESS,
  GET_EMPLOYEE_DATA_SUCCESS,
  GET_TASKS_PRIORITY_STATUS_SUCCESS,
  GET_INITIATIVE_TRACKER_DETAILS_SUCCESS,
  GET_EXISTING_FUNCTIONS,
  GET_EXISTING_FUNCTIONS_SUCCESS,
  GET_EXISTING_GOALS,
  GET_EXISTING_GOALS_SUCCESS,
  GET_SINGLE_TASK_DATA,
  GET_TASK_DATA_SUCCESS,
  POST_TASK_COMMENTS,
  POST_TASK_COMMENTS_SUCCESS,
  POST_FUNGOAL_DEPENDECIES,
  POST_FUNGOAL_DEPENDECIES_SUCCESS,
  POST_FUNGOAL_KEYDECISIONS,
  POST_FUNGOAL_KEYDECISIONS_SUCCESS,
  POST_FUNGOAL_RISK_ISSUES,
  POST_FUNGOAL_RISK_ISSUES_SUCCESS,
  GET_FUNCTIONAL_GOAL_DETAILS,
  GET_FUNCTIONAL_GOAL_DETAILS_SUCCESS,
  GET_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS,
  POST_FUNGOAL_MEETING_REVIEW_DATA,
  POST_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS,
  GET_FUNGOAL_MEETING_REVIEW_DATA,
  POST_FUNGOALREVIEW_MEETING_PROGRESS_SUCCESS,
  POST_FUNGOALREVIEW_MEETING_PROGRESS,
  POST_FUNGOALREVIEW_MEETING_NEXTSTEPS_SUCCESS,
  POST_FUNGOALREVIEW_MEETING_NEXTSTEPS,
  GET_EXECUTION_READINESS_FUNCTION,
  POST_DASHBOARD_STATE_DETAILS,
  GET_DASHBOARD_STATE_DETAILS,
  GET_DASHBOARD_STATE_DETAILS_SUCCESS,
  POST_DASHBOARD_STATE_DETAILS_SUCCESS,
  GET_STRATEGY_READINESS_FUNCTION,
  GET_EXECUTION_READINESS_FUNCTION_SUCCESS,
  GET_EXECUTION_READINESS_FUNCTIONALAREA,
  GET_STRATEGY_READINESS_FUNCTION_SUCCESS,
  POST_STRATEGY_READINESS_FUNCTION,
  POST_STRATEGY_READINESS_FUNCTION_SUCCESS,
  GET_CALENDER_MEETING_DETAILS_SUCCESS,
  GET_CALENDER_MEETING_DETAILS,
  GET_DASHBOARD_TASK_DETAILS,
  GET_DASHBOARD_TASK_DETAILS_SUCCESS,
  GET_ECOSYSTEM_MAPPING_SECTION_DETAILS,
  GET_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS,
  POST_ECOSYSTEM_MAPPING_SECTION_DETAILS,
  POST_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS,
  GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA,
  GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA_SUCCESS,
  POST_EXECUTION_READINESS_FUNCTIONALAREA,
  POST_EXECUTION_READINESS_FUNCTIONALAREA_SUCCESS,
  GET_NOTIFICATION_DETAILS,
  GET_NOTIFICATION_DETAILS_SUCCESS,
  POST_NOTIFICATION_DETAILS_SUCCESS,
  POST_NOTIFICATION_DETAILS,
  GET_FILE_MANAGER_DETAILS,
  GET_FILE_MANAGER_DETAILS_SUCCESS,
  POST_FILE_MANAGER_DETAILS,
  POST_FILE_MANAGER_DETAILS_SUCCESS,
  SET_SELECTED_NEW_BI,
  GET_ANSOFF_MATRIX_GRID_DETAILS_SUCCESS,
  GET_ANSOFF_MATRIX_GRID_DETAILS,
  POST_ANSOFF_MATRIX_DETAILS_SUCCESS,
  POST_ANSOFF_MATRIX_DETAILS,
  POST_EXECUTION_READINESS_DETAILS,
  POST_EXECUTION_READINESS_DETAILS_SUCCESS,
  GET_EXECUTION_READINESS_REVIEW_DETAILS,
  GET_EXECUTION_READINESS_REVIEW_DETAILS_SUCCESS,
  POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS,
  POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS,
  POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS,
  POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS,
  SET_SELECTED_HELP_INFO_SIDEBAR,
  GET_GLOBAL_FILE_MANAGER_DETAILS,
  GET_GLOBAL_FILE_MANAGER_DETAILS_SUCCESS,
  POST_GLOBAL_FILE_MANAGER_DETAILS,
  POST_GLOBAL_FILE_MANAGER_DETAILS_SUCCESS,
  POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS,
  POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS_SUCCESS,
  GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS,
  GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS_SUCCESS,
  GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA,
  GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA_SUCCESS,
  POST_STRATEGY_READINESS_FUNCTIONALAREA,
  POST_STRATEGY_READINESS_FUNCTIONALAREA_SUCCESS,
  GET_STRATEGY_READINESS_FUNCTIONALAREA,
  GET_STRATEGY_READINESS_REVIEW_DETAILS,
  GET_STRATEGY_READINESS_REVIEW_DETAILS_SUCCESS,
  POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS,
  POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS,
  POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS,
  POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS,
  SET_SELECTED_PERMISSION,
  SET_SELECTED_SUB_MENU_PERMISSION,
  POST_FOLDER_MANAGER_ADD_NEW,
  POST_FOLDER_MANAGER_EDIT,
  POST_FOLDER_MANAGER_DELETE,
  POST_FOLDER_MANAGER_ADD_NEW_SUCCESS,
  POST_FODER_MANAGER_EDIT_SUCCESS,
  POST_FOLDER_MANAGER_DELETE_SUCCESS,
  POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER,
  POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER_SUCCESS,
  GET_DRIVE_FILES,
  GET_DRIVE_FILES_SUCCESS,
  GET_PROJECT_SUMMARY_DETAILS,
  GET_PROJECT_SUMMARY_DETAILS_SUCCESS,
  POST_LOCAL_FOLDER_MANAGER,
  POST_LOCAL_FOLDER_MANAGER_SUCCESS,
  GET_LOCAL_FOLDER_MANAGER,
  GET_LOCAL_FOLDER_MANAGER_SUCCESS,
  POST_LOCAL_FOLDER_MANAGER_FILE_DELETE,
  POST_LOCAL_FOLDER_MANAGER_FILE_DELETE_SUCCESS,
  GET_SCREEN_COMMENTS,
  GET_SCREEN_COMMENTS_SUCCESS,
  POST_SCREEN_COMMENTS,
  POST_SCREEN_COMMENTS_SUCCESS,
  SET_TEMPLATE_BTN_SELECTED,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_SUCCESS,
  FORGOT_USER_PASSWORD,
  FORGOT_USER_PASSWORD_SUCCESS,
  GET_BI_FGOAL_STATUS,
  GET_BI_FGOAL_STATUS_SUCCESS,
  POST_BI_FGOAL_STATUS,
  POST_BI_FGOAL_STATUS_SUCCESS,
  GET_GLOBAL_SERACH_FILTER,
  GET_GLOBAL_SERACH_FILTER_SUCCESS,
  GET_IS_MAINTENANCE_MODE,
  GET_IS_MAINTENANCE_MODE_SUCCESS,
  IS_MAINTENANCE_MODE,
  GET_XLSTRATEGYPLAN_DETAILS,
  GET_XLSTRATEGYPLAN_DETAILS_SUCCESS,
  POST_XLSTRATEGYPLAN_DETAILS,
  POST_XLSTRATEGYPLAN_DETAILS_SUCCESS,
  GET_EXPORT_WORBOARD_CSV_FILE_SUCCESS,
  GET_EXPORT_WORBOARD_CSV_FILE,
} from "./actionTypes";

export const exceptionError = (response) => {
  return {
    type: EXCEPTION_ERROR,
    ISError: response,
  };
};

export const loadingStart = () => {
  return {
    type: LOADING_START,
  };
};

export const exceptionSessionExpire = (response, test) => {
  return {
    type: SESSION_EXPIRE_ERROR,
    sessionError: response,
    test: test,
  };
};

export const exceptionAPIError = (response) => {
  return {
    type: SESSION_API_ERROR,
    apiError: response,
  };
};

export const resetExceptionError = () => {
  return { type: RESET_EXCEPTION_ERROR };
};

export const userLogin = (username, password, submitted) => {
  return {
    type: USER_LOGIN_INITIATE,
    username,
    password,
    submitted,
  };
};

export const userLoginSuccess = (response, submitted) => {
  return {
    type: LOGIN_SUCCESS,
    userDetails: response,
    submitted,
  };
};
export const userUpdatePassword = (new_password, key) => {
  return {
    type: UPDATE_USER_PASSWORD,
    new_password,
    key,
  };
};

export const userUpdatePasswordSuccess = (response) => {
  return {
    type: UPDATE_USER_PASSWORD_SUCCESS,
    response,
  };
};

export const userForgotPassword = (email, domain) => {
  return {
    type: FORGOT_USER_PASSWORD,
    email,
    domain,
  };
};

export const userForgotPasswordSuccess = (response) => {
  return {
    type: FORGOT_USER_PASSWORD_SUCCESS,
    response,
  };
};

export const getOrgDetails = () => {
  return {
    type: GET_ORG_DETAILS,
  };
};

export const getOrgDetailsSuccess = (response) => {
  return {
    type: GET_ORG_DETAILS_SUCCESS,
    orgDetailsData: response,
  };
};

export const uiMenuList = (bi_id, product_id) => {
  return {
    type: UI_MENU_LIST,
    bi_id,
    product_id,
  };
};

export const uiMenuListSuccess = (response) => {
  return {
    type: UI_MENU_LIST_SUCCESS,
    uiListDetails: response,
  };
};

export const biDetailsList = () => {
  return {
    type: BI_DETAILS_LIST,
  };
};

export const biDetails = (bi_id) => {
  return {
    type: BI_DETAILS,
    bi_id,
  };
};

export const biDetailsPost = (
  bi_id,
  bi_name,
  bi_owner,
  sector,
  sub_sector,
  obj,
  desc,
  start_dt,
  target_dt,
  business_name
) => {
  return {
    type: BI_DETAILS_POST,
    bi_id,
    bi_name,
    bi_owner,
    sector,
    sub_sector,
    obj,
    desc,
    start_dt,
    target_dt,
    business_name,
  };
};

export const biDetailsSuccess = (response) => {
  return {
    type: BI_DETAILS_SUCCESS,
    biDetails: response,
  };
};
export const biDetailsListSuccess = (response) => {
  return {
    type: BI_DETAILS_LIST_SUCCESS,
    biDetails: response,
  };
};

export const biDetailsGetSuccess = (response) => {
  return {
    type: BI_DETAILS_GET_SUCCESS,
    biDetailsGetSuccess: response,
  };
};

export const biDetailsPostSuccess = (response) => {
  return {
    type: BI_DETAILS_POST_SUCCESS,
    bidetailsPost: response,
  };
};

export const bisectorGet = (bi_id) => {
  return {
    type: BI_SECTOR_GET,
    bi_id,
  };
};

export const bisectorPost = (bi_id, sec_id) => {
  return {
    type: BI_SECTOR_POST,
    bi_id,
    sec_id,
  };
};

export const bisectorGetSuccess = (response) => {
  return {
    type: BI_SECTOR_GET_SUCCESS,
    biSectorGet: response,
  };
};

export const bisectorPostSuccess = (response) => {
  return {
    type: BI_SECTOR_POST_SUCCESS,
    biSectorPost: response,
  };
};

export const bisubsectorGet = (data, sectorChange) => {
  return {
    type: BI_SUB_SECTOR_GET,
    data,
    sectorChange,
  };
};

export const bisubsectorPost = (bi_id, sub_sec_id) => {
  return {
    type: BI_SUB_SECTOR_POST,
    bi_id,
    sub_sec_id,
  };
};

export const bisubsectorGetSuccess = (response, sectorChange) => {
  return {
    type: BI_SUB_SECTOR_GET_SUCCESS,
    sectorChange: sectorChange,
    biSubSectorGet: response,
  };
};

export const bisubsectorPostSuccess = (response) => {
  return {
    type: BI_SUB_SECTOR_POST_SUCCESS,
    biSubSectorPost: response,
  };
};
export const bibusinesstypeGet = (bi_id) => {
  return {
    type: BI_BUSINESS_TYPE_GET,
    bi_id,
  };
};

export const bibusinesstypePost = (bi_id, bussiness_name) => {
  return {
    type: BI_BUSINESS_TYPE_POST,
    bi_id,
    bussiness_name,
  };
};

export const bibusinesstypeGetSuccess = (response) => {
  return {
    type: BI_BUSINESS_TYPE_GET_SUCCESS,
    biBusinessTypeGet: response,
  };
};

export const bibusinesstypePostSuccess = (response) => {
  return {
    type: BI_BUSINESS_TYPE_POST_SUCCESS,
    biBusinessTypePost: response,
  };
};

export const biproductdetailsGet = (bi_id) => {
  return {
    type: BI_PRODUCT_DETAILS_GET,
    bi_id,
  };
};

export const biproductdetailsPost = (
  bi_id,
  product_name,
  product_category,
  desc,
  action,
  product_id,
  is_active
) => {
  return {
    type: BI_PRODUCT_DETAILS_POST,
    bi_id,
    product_name,
    product_category,
    desc,
    action,
    product_id,
    is_active,
  };
};

export const biproductdetailsGetSuccess = (response) => {
  return {
    type: BI_PRODUCT_DETAILS_GET_SUCCESS,
    biProductDetailsGet: response,
  };
};

export const biproductdetailsPostSuccess = (response) => {
  return {
    type: BI_PRODUCT_DETAILS_POST_SUCCESS,
    biProductDetailsPost: response,
  };
};

export const vcListGet = (bi_id, isVCFetchedAfterAdd) => {
  return {
    type: VC_LIST_GET,
    bi_id,
    isVCFetchedAfterAdd,
  };
};

export const vcListGetSuccess = (response, isVCFetchedAfterAdd) => {
  return {
    type: VC_LIST_GET_SUCCESS,
    vcListGetData: response,
    isVCFetchedAfterAdd,
  };
};

export const vcSelectedGet = (value_id, bi_id, selector, vcChanged) => {
  return {
    type: VC_SELECTED_GET,
    value_id,
    bi_id,
    selector,
    vcChanged,
  };
};

export const vcSelectedGetSuccess = (response, vcChanged) => {
  return {
    type: VC_SELECTED_GET_SUCCESS,
    vcSelectedGetData: response,
    vcChanged: vcChanged,
  };
};

export const vcCreate = (data, bi_id, vcAdded) => {
  return {
    type: VC_CREATE,
    data,
    bi_id,
    vcAdded,
  };
};

export const vcCreateGetSuccess = (response, vcAdded) => {
  return {
    type: VC_CREATE_GET_SUCCESS,
    vcCreateGetData: response,
    vcAdded,
  };
};

export const vcChangeTableData = (
  bi_id,
  catg_id,
  product_id,
  dimension_id,
  is_selected,
  vcStateChanged
) => {
  return {
    type: VC_CHANGE_TABLE_DATA,
    bi_id,
    catg_id,
    product_id,
    dimension_id,
    is_selected,
    vcStateChanged,
  };
};

export const vcChangeTableDataSuccess = (response, vcStateChanged) => {
  return {
    type: VC_CHANGE_TABLE_DATA_SUCCESS,
    vcAddTableData: response,
    vcStateChanged,
  };
};

export const addCustomerVCSelection = (bi_id, data, is_selection, vcEdited) => {
  return {
    type: ADD_CUSTOMER_VC_SELECTION,
    bi_id,
    data,
    is_selection,
    vcEdited,
  };
};

export const addCustomerVCSelectionSuccess = (response, vcEdited) => {
  return {
    type: ADD_CUSTOMER_VC_SELECTION_SUCCESS,
    addCustomerVCSelectionData: response,
    vcEdited,
  };
};

export const getCustomerVCSelection = (
  bi_id,
  value_name_id,
  isInitialVCFetched,
  isUpdatedVCFetched,
  vcFetched,
  vcnamecheck
) => {
  return {
    type: GET_CUSTOMER_VC_SELECTION,
    bi_id,
    value_name_id,
    isInitialVCFetched,
    isUpdatedVCFetched,
    vcFetched,
    vcnamecheck,
  };
};

export const getCustomerVCSelectionSuccess = (
  response,
  isInitialVCFetched,
  isUpdatedVCFetched,
  vcFetched
) => {
  return {
    type: GET_CUSTOMER_VC_SELECTION_SUCCESS,
    getCustomerVCSelectionData: response,
    isInitialVCFetched,
    isUpdatedVCFetched,
    vcFetched,
  };
};

export const removeCustomerVCSelection = (bi_id, value_chain_id, isactive) => {
  return {
    type: REMOVE_CUSTOMER_VC_SELECTION,
    bi_id,
    value_chain_id,
    isactive,
  };
};

export const removeCustomerVCSelectionSuccess = (response) => {
  return {
    type: REMOVE_CUSTOMER_VC_SELECTION_SUCCESS,
    removeCustomerVCSelectionData: response,
  };
};

export const editDimensions = (bi_id, data, isEditSave) => {
  return {
    type: EDIT_DIMENSIONS,
    bi_id,
    data,
    isEditSave,
  };
};

export const addCategory = (bi_id, name_id, catg_name, isAddCat) => {
  return {
    type: ADD_CATEGORY,
    bi_id,
    name_id,
    catg_name,
    isAddCat,
  };
};

export const addCategorySuccess = (response, isAddCat) => {
  return {
    type: ADD_CATEGORY_SUCCESS,
    getAddedCategoryData: response,
    isAddCat,
  };
};

export const addDimension = (bi_id, ctg_id, dimension_name, isAddDimension) => {
  return {
    type: ADD_DIMENSION,
    bi_id,
    ctg_id,
    dimension_name,
    isAddDimension,
  };
};

export const addDimensionSuccess = (response, isAddDimension) => {
  return {
    type: ADD_DIMENSION_SUCCESS,
    getAddedDimensionData: response,
    isAddDimension,
  };
};

export const addChildDimension = (
  bi_id,
  ctg_id,
  dimension_name,
  isAddChildDimension
) => {
  return {
    type: ADD_CHILD_DIMENSION,
    bi_id,
    ctg_id,
    dimension_name,
    isAddChildDimension,
  };
};

export const addChildDimensionSuccess = (response, isAddChildDimension) => {
  return {
    type: ADD_CHILD_DIMENSION_SUCCESS,
    getAddedChildDimensionData: response,
    isAddChildDimension,
  };
};

export const addCurrentDocURL = (url, isadded) => {
  return {
    type: ADD_CURRENT_DOC_URL,
    template_url: url,
    isURLAdded: isadded,
  };
};

export const addScreenID = (id, isadded) => {
  return {
    type: ADD_SCREEN_ID,
    screen_id: id,
    isScreenIDAdded: isadded,
  };
};
export const addMainScreenID = (id, isadded) => {
  return {
    type: ADD_MAIN_SCREEN_ID,
    main_screen_id: id,
    isMainScreenIDAdded: isadded,
  };
};

export const getProductsDetails = (bi_id) => {
  return {
    type: GET_PRODUCTS_DETAILS,
    bi_id: bi_id,
  };
};

export const getProductsDetailsSuccess = (response) => {
  return {
    type: GET_PRODUCTS_DETAILS_SUCCESS,
    getBIProductsData: response,
  };
};

export const getAnsoffMatrixDetails = (bi_id, product_id) => {
  return { type: GET_ANSOFF_MATRIX_DETAILS, bi_id, product_id };
};

export const getAnsoffMatrixDetailsSuccess = (response) => {
  console.log(response);
  return {
    type: GET_ANSOFF_MATRIX_DETAILS_SUCCESS,
    getAnsoffMatrixData: response,
  };
};

export const getMarketSizeDetails = (bi_id, product_id) => {
  return {
    type: GET_MARKET_SIZE_DETAILS,
    bi_id: bi_id,
    product_id,
  };
};

export const getMarketSizeDetailsSuccess = (response) => {
  return {
    type: GET_MARKET_SIZE_DETAILS_SUCCESS,
    getMarketSizeData: response,
  };
};

export const postMarketSizeDetails = (
  bi_id,
  product_id,
  market_size,
  unit,
  currency,
  remarks
) => {
  return {
    type: POST_MARKET_SIZE_DETAILS,
    bi_id: bi_id,
    product_id,
    market_size,
    unit,
    currency,
    remarks,
  };
};

export const postMarketSizeDetailsSuccess = (response) => {
  return {
    type: POST_MARKET_SIZE_DETAILS_SUCCESS,
    postedMarketSizeData: response,
  };
};

export const getCompetitorDetails = (
  bi_id,
  product_id,
  isCompetitorUpdated
) => {
  return {
    type: GET_COMPETITOR_DETAILS,
    bi_id: bi_id,
    product_id,
    isCompetitorUpdated,
  };
};

export const getCompetitorDetailsSuccess = (response, isCompetitorUpdated) => {
  return {
    type: GET_COMPETITOR_DETAILS_SUCCESS,
    getCompetitorData: response,
    isCompetitorUpdated,
  };
};

export const postCompetitorDetails = (
  bi_id,
  product_id,
  competitor,
  selected_dt,
  isCompetitorAdded,
  isCompetitorSaved,
  isCompetitorDetailsAdded,
  isCompetitorDetailsSaved
) => {
  return {
    type: POST_COMPETITOR_DETAILS,
    bi_id: bi_id,
    product_id,
    competitor,
    selected_dt,
    isCompetitorAdded,
    isCompetitorSaved,
    isCompetitorDetailsAdded,
    isCompetitorDetailsSaved,
  };
};

export const postCompetitorDetailsSuccess = (
  response,
  isCompetitorAdded,
  isCompetitorSaved,
  isCompetitorDetailsAdded,
  isCompetitorDetailsSaved
) => {
  return {
    type: POST_COMPETITOR_DETAILS_SUCCESS,
    postedCompetitorData: response,
    isCompetitorAdded,
    isCompetitorSaved,
    isCompetitorDetailsAdded,
    isCompetitorDetailsSaved,
  };
};

export const getCompAnalDetails = (bi_id, product_id, isCompAnalFetched) => {
  return {
    type: GET_COMPETITOR_ANALYSIS_DETAILS,
    bi_id,
    product_id,
    isCompAnalFetched,
  };
};

export const getCompAnalDetailsSuccess = (response, isCompAnalFetched) => {
  return {
    type: GET_COMPETITOR_ANALYSIS_DETAILS_SUCCESS,
    getCompAnalData: response,
    isCompAnalFetched,
  };
};

export const postCompAnalDetails = (
  bi_id,
  product_id,
  feature_id,
  competitor_id,
  isactive,
  feature_type_id
) => {
  return {
    type: POST_COMPETITOR_ANALYSIS_DETAILS,
    bi_id: bi_id,
    product_id,
    feature_id,
    competitor_id,
    isactive,
    feature_type_id,
  };
};

export const postCompAnalDetailsSuccess = (response) => {
  return {
    type: POST_COMPETITOR_ANALYSIS_DETAILS_SUCCESS,
    postedCompAnalData: response,
  };
};

export const getFeatureTypeDetails = (
  bi_id,
  product_id,
  isFeatureDetailsFeched
) => {
  return {
    type: GET_FETAURE_TYPE_DETAILS,
    bi_id: bi_id,
    product_id,
    isFeatureDetailsFeched,
  };
};

export const getFeatureTypeDetailsSuccess = (
  response,
  isFeatureDetailsFeched
) => {
  return {
    type: GET_FETAURE_TYPE_DETAILS_SUCCESS,
    getFeatureTypeData: response,
    isFeatureDetailsFeched,
  };
};

export const postFeatureDetails = (
  bi_id,
  product_id,
  data,
  isFeaturePosted,
  featureID
) => {
  return {
    type: POST_FETAURE_DETAILS,
    bi_id,
    product_id,
    data,
    isFeaturePosted,
    featureID,
  };
};

export const postFeatureDetailsSuccess = (
  response,
  isFeaturePosted,
  featureID
) => {
  return {
    type: POST_FETAURE_DETAILS_SUCCESS,
    postedFeatureData: response,
    isFeaturePosted,
    featureID,
  };
};

export const getMetrics = (bi_id, product_id, isMetricsFeched) => {
  return {
    type: GET_METRICS,
    bi_id: bi_id,
    product_id,
    isMetricsFeched,
  };
};

export const getMetricsSuccess = (response, isMetricsFeched) => {
  return {
    type: GET_METRICS_SUCCESS,
    getMetricData: response,
    isMetricsFeched,
  };
};

export const postMetrics = (
  bi_id,
  product_id,
  create_data,
  select_data,
  metric_id,
  isMetricsSaved,
  isMetricSelected
) => {
  return {
    type: POST_METRICS,
    bi_id,
    product_id,
    create_data,
    select_data,
    metric_id,
    isMetricsSaved,
    isMetricSelected,
  };
};

export const postMetricsSuccess = (
  response,
  isMetricsSaved,
  isMetricSelected
) => {
  return {
    type: POST_METRICS_SUCCESS,
    postedMetricData: response,
    isMetricsSaved,
    isMetricSelected,
  };
};

export const getMSDocs = (
  bi_id,
  screen_id,
  main_screen_id,
  getScreenName,
  isMSDocsPostDone,
  folder_type,
  sub_folder_id
) => {
  return {
    type: GET_MS_DOCS,
    bi_id,
    screen_id,
    main_screen_id,
    getScreenName,
    isMSDocsPostDone,
    folder_type,
    sub_folder_id,
  };
};

export const getMSDocsSuccess = (response, isMSDocsPostDone, getScreenName) => {
  return {
    type: GET_MS_DOCS_SUCCESS,
    response,
    isMSDocsPostDone: isMSDocsPostDone,
    getScreenName,
  };
};

export const postMSDocs = (
  bi_id,
  screen_id,
  main_screen_id,
  doc,
  screen_name,
  isMSDocsPosted
) => {
  return {
    type: POST_MS_DOCS,
    bi_id,
    screen_id,
    main_screen_id,
    doc,
    screen_name,
    isMSDocsPosted,
  };
};

export const postMSDocsSuccess = (response, isMSDocsPosted, screen_name) => {
  return {
    type: POST_MS_DOCS_SUCCESS,
    response,
    isMSDocsPosted: isMSDocsPosted,
    screen_name,
  };
};

export const getNotes = (note_id, bi_id, meetingaction, isActionChanged) => {
  return {
    type: GET_NOTES,
    note_id,
    isActionChanged,
    meetingaction,
    bi_id,
  };
};

export const getNotesSuccess = (response, isActionChanged) => {
  return {
    type: GET_NOTES_SUCCESS,
    response,
    isActionChanged: isActionChanged,
  };
};

export const postNotes = (notes_title, bi_id, note_id, content, action) => {
  return {
    type: POST_NOTES,
    notes_title,
    bi_id,
    note_id,
    content,
    action,
  };
};

export const postNotesSuccess = (response, isActionChanged) => {
  return {
    type: POST_NOTES_SUCCESS,
    response,
    isActionChanged: isActionChanged,
  };
};

export const getMeeting = (bi_id) => {
  return {
    type: GET_MEETING,
    bi_id,
  };
};

export const getMeetingSuccess = (response) => {
  return {
    type: GET_MEETING_SUCCESS,
    response,
  };
};

export const postMeeting = (bi_id, data) => {
  return {
    type: POST_MEETING,
    bi_id,
    data,
  };
};

export const postMeetingSuccess = (response) => {
  return {
    type: POST_MEETING_SUCCESS,
    response,
  };
};

export const getKnowledgeBase = (screen_id, tag = false) => {
  return {
    type: GET_KNOWLEDGE_BASE,
    screen_id,
    tag,
  };
};

export const getKnowledgeBaseSuccess = (response) => {
  return {
    type: GET_KNOWLEDGE_BASE_SUCCESS,
    response,
  };
};

export const postCopyKnowledgeBaseTemplate = (
  screen_id,
  bi_id,
  files,
  templateId
) => {
  return {
    type: POST_COPY_KNOWLEDGE_BASE,
    screen_id,
    bi_id,
    files,
    templateId,
  };
};

export const postCopyKnowledgeBaseTemplateSuccess = (response) => {
  return {
    type: POST_COPY_KNOWLEDGE_BASE_SUCCESS,
    response,
  };
};

// export const postCopyMasterFunctionsXLExecution = (bi_id, product_id) => {
//   return {
//     type: POST_COPY_MASTER_FUNCTIONS_XLEXECUTION,
//     bi_id,
//     product_id,
//   };
// };

export const getXLExecutionPlanDetails = (
  bi_id,
  product_id,
  function_id = false,
  task_filter
) => {
  return {
    type: GET_XLEXECUTION_PLAN_DETAILS,
    bi_id,
    product_id,
    function_id,
    task_filter,
  };
};

export const getXLExecutionPlanDetailsSuccess = (response) => {
  return {
    type: GET_XLEXECUTION_PLAN_DETAILS_SUCCESS,
    response,
  };
};

export const postEditStrategyObjective = (bi_id, obj) => {
  return {
    type: POST_EDIT_STRATEGY_OBJECTIVE,
    bi_id,
    obj,
  };
};

export const postStrategyGoals = (data) => {
  return {
    type: POST_STRATEGY_GOALS,
    data,
  };
};

export const postStrategyGoalSuccess = (response) => {
  return {
    type: POST_STRATEGY_GOAL_SUCCESS,
    response,
  };
};

export const postStrategyFunctions = (
  data,
  stage_type,
  is_function = false,
  is_imperative = false,
  is_milestone = false,
  is_task = false,
  is_subtask = false
) => {
  return {
    type: POST_STRATEGY_FUNCTIONS,
    data,
    stage_type,
    is_function,
    is_imperative,
    is_milestone,
    is_task,
    is_subtask,
  };
};

export const postStrategyFunctionsSuccess = (response) => {
  return {
    type: POST_STRATEGY_FUNCTIONS_SUCCESS,
    response,
  };
};

// export const getDepartments = () => {
//   return {
//     type: GET_DEPARTMENTS,
//   };
// };

// export const getDepartmentsSuccess = (response) => {
//   return {
//     type: GET_DEPARTMENTS_SUCCESS,
//     response,
//   };
// };

export const getEmployeeData = () => {
  return {
    type: GET_EMPLOYEE_DATA,
  };
};

export const getEmployeeDataSuccess = (response) => {
  return {
    type: GET_EMPLOYEE_DATA_SUCCESS,
    response,
  };
};

export const getMasterTaskPriorityAndStatusData = (
  bi_id,
  product_id,
  function_id
) => {
  return {
    type: GET_TASKS_PRIORITY_STATUS,
    bi_id,
    product_id,
    function_id,
  };
};

export const getMasterTaskPriorityAndStatusDataSuccess = (response) => {
  return {
    type: GET_TASKS_PRIORITY_STATUS_SUCCESS,
    response,
  };
};

export const getInitiativeTrackerDetails = (bi_id, product_id) => {
  return {
    type: GET_INITIATIVE_TRACKER_DETAILS,
    bi_id,
    product_id,
  };
};

export const getInitiativeTrackerDetailsSuccess = (response) => {
  return {
    type: GET_INITIATIVE_TRACKER_DETAILS_SUCCESS,
    response,
  };
};

export const getChatAction = () => {
  return {
    type: GET_CHAT_DETAILS_INFO,
  };
};

export const clearChatAction = (response = null) => {
  return {
    type: GET_CHAT_DETAILS_INFO,
    chatActionInfo: response,
  };
};

export const setChatAction = (response) => {
  return {
    type: GET_CHAT_DETAILS_INFO,
    chatActionInfo: response,
  };
};

export const getExistingGoals = (bi_id, product_id) => {
  return {
    type: GET_EXISTING_GOALS,
    bi_id,
    product_id,
  };
};

export const getExistingGoalsSuccess = (response) => {
  return {
    type: GET_EXISTING_GOALS_SUCCESS,
    response,
  };
};

export const getExistingFunctions = (bi_id, product_id, is_active = false) => {
  return {
    type: GET_EXISTING_FUNCTIONS,
    bi_id,
    product_id,
    is_active,
  };
};

export const getExistingFunctionsSuccess = (response) => {
  return {
    type: GET_EXISTING_FUNCTIONS_SUCCESS,
    response,
  };
};

export const getTaskDetails = (task_id) => {
  return {
    type: GET_SINGLE_TASK_DATA,
    task_id,
  };
};

export const getTaskDetailsSuccess = (response) => {
  return {
    type: GET_TASK_DATA_SUCCESS,
    response,
  };
};

export const postTaskComments = (content, task_id, action, comment_id) => {
  return {
    type: POST_TASK_COMMENTS,
    content,
    task_id,
    action,
    comment_id,
  };
};

export const postTaskCommentsSuccess = (response) => {
  return {
    type: POST_TASK_COMMENTS_SUCCESS,
    response,
  };
};

export const postFunGoalDependencies = (
  imperative_id,
  fungoal_review_id,
  title,
  desc,
  due_date,
  dep_status,
  action,
  dependency_id
) => {
  return {
    type: POST_FUNGOAL_DEPENDECIES,
    imperative_id,
    fungoal_review_id,
    title,
    desc,
    due_date,
    dep_status,
    action,
    dependency_id,
  };
};

export const postFunGoalDependenciesSuccess = (response) => {
  return {
    type: POST_FUNGOAL_DEPENDECIES_SUCCESS,
    response,
  };
};

export const postFunGoalKeyDecisions = (
  imperative_id,
  fungoal_review_id,
  title,
  desc,
  due_date,
  action,
  assigned_to,
  key_desc_id
) => {
  return {
    type: POST_FUNGOAL_KEYDECISIONS,
    imperative_id,
    fungoal_review_id,
    title,
    desc,
    due_date,
    action,
    assigned_to,
    key_desc_id,
  };
};

export const postFunGoalKeyDecisionsSuccess = (response) => {
  return {
    type: POST_FUNGOAL_KEYDECISIONS_SUCCESS,
    response,
  };
};

export const postFunGoalKeyRiskIssues = (
  imperative_id,
  fungoal_review_id,
  title,
  desc,
  due_date,
  rating,
  mitigation,
  action,
  assigned_to,
  risk_id
) => {
  return {
    type: POST_FUNGOAL_RISK_ISSUES,
    imperative_id,
    fungoal_review_id,
    title,
    desc,
    due_date,
    rating,
    mitigation,
    action,
    assigned_to,
    risk_id,
  };
};

export const postFunGoalKeyRiskIssuesSuccess = (response) => {
  return {
    type: POST_FUNGOAL_RISK_ISSUES_SUCCESS,
    response,
  };
};

export const getFunctionalGoals = (
  bi_id,
  product_id,
  functional_goal_id,
  goal_review_id
) => {
  return {
    type: GET_FUNCTIONAL_GOAL_DETAILS,
    bi_id,
    product_id,
    functional_goal_id,
    goal_review_id,
  };
};

export const getFunctionalGoalsSuccess = (response) => {
  return {
    type: GET_FUNCTIONAL_GOAL_DETAILS_SUCCESS,
    response,
  };
};

export const getFunctionalGoalReviewMeetingData = (imperative_id, bi_id) => {
  return {
    type: GET_FUNGOAL_MEETING_REVIEW_DATA,
    imperative_id,
    bi_id,
  };
};

export const getFunctionalGoalReviewMeetingDataSuccess = (response) => {
  return {
    type: GET_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS,
    response,
  };
};

export const postFunctionalGoalReviewMeetingData = (imperative_id, data) => {
  return {
    type: POST_FUNGOAL_MEETING_REVIEW_DATA,
    imperative_id,
    data,
  };
};

export const postFunctionalGoalReviewMeetingDataSuccess = (response) => {
  return {
    type: POST_FUNGOAL_MEETING_REVIEW_DATA_SUCCESS,
    response,
  };
};

export const postFunctionalGoalReviewMeetingProgressData = (
  imperative_id,
  desc,
  action,
  review_id,
  progress_id
) => {
  return {
    type: POST_FUNGOALREVIEW_MEETING_PROGRESS,
    imperative_id,
    desc,
    action,
    review_id,
    progress_id,
  };
};

export const postFunctionalGoalReviewMeetingProgressDataSuccess = (
  response
) => {
  return {
    type: POST_FUNGOALREVIEW_MEETING_PROGRESS_SUCCESS,
    response,
  };
};

export const postFunctionalGoalReviewMeetingNextStepsData = (
  imperative_id,
  desc,
  action,
  next_step_id,
  review_id
) => {
  return {
    type: POST_FUNGOALREVIEW_MEETING_NEXTSTEPS,
    imperative_id,
    desc,
    action,
    next_step_id,
    review_id,
  };
};

export const postFunctionalGoalReviewMeetingNextStepsSuccess = (response) => {
  return {
    type: POST_FUNGOALREVIEW_MEETING_NEXTSTEPS_SUCCESS,
    response,
  };
};

export const setAllQuestions = (response) => {
  return {
    type: GET_EXECUTION_READINESS_FUNCTIONALAREA,
    allQuestionsList: response,
  };
};

export const getExecutionReadinessDetails = (bi_id, product_id) => {
  return {
    type: GET_EXECUTION_READINESS_FUNCTION,
    bi_id,
    product_id,
  };
};

export const getExecutionReadinessDetailsSuccess = (response) => {
  return {
    type: GET_EXECUTION_READINESS_FUNCTION_SUCCESS,
    response,
  };
};

export const getStrategyReadinessDetails = (bi_id, product_id) => {
  return {
    type: GET_STRATEGY_READINESS_FUNCTION,
    bi_id,
    product_id,
  };
};

export const getStrategyReadinessDetailsSuccess = (response) => {
  return {
    type: GET_STRATEGY_READINESS_FUNCTION_SUCCESS,
    response,
  };
};

export const postStrategyReadinessDetails = (data) => {
  return {
    type: POST_STRATEGY_READINESS_FUNCTION,
    data,
  };
};
export const postStrategyReadinessDetailsSuccess = (response) => {
  return {
    type: POST_STRATEGY_READINESS_FUNCTION_SUCCESS,
    response,
  };
};

export const getdashboardStateManagement = () => {
  return {
    type: GET_DASHBOARD_STATE_DETAILS,
  };
};

export const getdashboardStateManagementSuccess = (response) => {
  return {
    type: GET_DASHBOARD_STATE_DETAILS_SUCCESS,
    response,
  };
};

export const postdashboardStateManagement = (
  bi_id,
  last_screen,
  is_completed,
  is_pending
) => {
  return {
    type: POST_DASHBOARD_STATE_DETAILS,
    bi_id,
    last_screen,
    is_completed,
    is_pending,
  };
};

export const postdashboardStateManagementSuccess = (response) => {
  return {
    type: POST_DASHBOARD_STATE_DETAILS_SUCCESS,
    response,
  };
};

export const getCalenderMeetingDetails = (
  bi_id,
  product_id,
  function_id,
  function_area_id
) => {
  return {
    type: GET_CALENDER_MEETING_DETAILS,
    bi_id,
    product_id,
    function_id,
    function_area_id,
  };
};

export const getCalenderMeetingDetailsSuccess = (response) => {
  return {
    type: GET_CALENDER_MEETING_DETAILS_SUCCESS,
    response,
  };
};

export const getDashboardTaskDetails = () => {
  return {
    type: GET_DASHBOARD_TASK_DETAILS,
  };
};

export const getDashboardTaskDetailsSuccess = (response) => {
  return {
    type: GET_DASHBOARD_TASK_DETAILS_SUCCESS,
    response,
  };
};

export const getEcosystemMappingSectionDetails = (bi_id) => {
  return {
    type: GET_ECOSYSTEM_MAPPING_SECTION_DETAILS,
    bi_id,
  };
};

export const getEcosystemMappingSectionDetailsSuccess = (response) => {
  return {
    type: GET_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS,
    response,
  };
};

export const postEcosystemMappingSectionDetails = (
  bi_id,
  sub_section_id,
  name,
  is_active,
  action,
  sub_section_child_id
) => {
  return {
    type: POST_ECOSYSTEM_MAPPING_SECTION_DETAILS,
    bi_id,
    sub_section_id,
    name,
    is_active,
    action,
    sub_section_child_id,
  };
};

export const postEcosystemMappingSectionDetailsSuccess = (response) => {
  return {
    type: POST_ECOSYSTEM_MAPPING_SECTION_DETAILS_SUCCESS,
    response,
  };
};

export const getExecutionReadinessFunctionalAreaDetails = (
  function_id,
  bi_id,
  product_id,
  main_screen_id,
  screen_id
) => {
  return {
    type: GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA,
    function_id,
    bi_id,
    product_id,
    main_screen_id,
    screen_id,
  };
};

export const getExecutionReadinessFunctionalAreaDetailsSuccess = (response) => {
  return {
    type: GET_EXECUTION_READINESS_FUNCTIONALAREA_DATA_SUCCESS,
    response,
  };
};

// export const postExecutionReadinessFunctionalAreaDetails = (
//   function_id,
//   function_area_id,
//   question_id,
//   answer_id,
//   is_master
// ) => {
//   return {
//     type: POST_EXECUTION_READINESS_FUNCTIONALAREA,
//     function_id,
//     function_area_id,
//     question_id,
//     answer_id,
//     is_master,
//   };
// };

// export const postExecutionReadinessFunctionalAreaDetailsSuccess = (
//   response
// ) => {
//   return {
//     type: POST_EXECUTION_READINESS_FUNCTIONALAREA_SUCCESS,
//     response,
//   };
// };

export const getNoticationsDetails = () => {
  return {
    type: GET_NOTIFICATION_DETAILS,
  };
};

export const getNoticationsDetailsSuccess = (response) => {
  return {
    type: GET_NOTIFICATION_DETAILS_SUCCESS,
    response,
  };
};

export const postNoticationsDetails = (notif_id) => {
  return {
    type: POST_NOTIFICATION_DETAILS,
    notif_id,
  };
};

export const postNoticationsDetailsSuccess = (response) => {
  return {
    type: POST_NOTIFICATION_DETAILS_SUCCESS,
    response,
  };
};

export const getFileManagerDetails = () => {
  return {
    type: GET_FILE_MANAGER_DETAILS,
  };
};

export const getFileManagerDetailsSuccess = (response) => {
  return {
    type: GET_FILE_MANAGER_DETAILS_SUCCESS,
    response,
  };
};

export const postFileManagerDetails = () => {
  return {
    type: POST_FILE_MANAGER_DETAILS,
  };
};

export const postFileManagerDetailsSuccess = (response) => {
  return {
    type: POST_FILE_MANAGER_DETAILS_SUCCESS,
    response,
  };
};

export const setSelectedNewBI = (value) => {
  return {
    type: SET_SELECTED_NEW_BI,
    value,
  };
};
export const getAnsoffMatrixGridDetails = (bi_id, product_id) => {
  return {
    type: GET_ANSOFF_MATRIX_GRID_DETAILS,
    bi_id,
    product_id,
  };
};

export const getAnsoffMatrixGridDetailsSuccess = (response) => {
  return {
    type: GET_ANSOFF_MATRIX_GRID_DETAILS_SUCCESS,
    response,
  };
};

export const postAnsoffMatrixDetails = (
  bi_id,
  product_id,
  leftpanelData,
  gridData
) => {
  return {
    type: POST_ANSOFF_MATRIX_DETAILS,
    bi_id,
    product_id,
    leftpanelData,
    gridData,
  };
};

export const postAnsoffMatrixDetailsSuccess = (response) => {
  return {
    type: POST_ANSOFF_MATRIX_DETAILS_SUCCESS,
    response,
  };
};
export const postExecutionReadinessDetails = (data) => {
  return {
    type: POST_EXECUTION_READINESS_DETAILS,
    data,
  };
};
export const postExecutionReadinessDetailsSuccess = (response) => {
  return {
    type: POST_EXECUTION_READINESS_DETAILS_SUCCESS,
    response,
  };
};
export const getExectionReadinessReviewDetails = (bi_id, product_id) => {
  return {
    type: GET_EXECUTION_READINESS_REVIEW_DETAILS,
    bi_id,
    product_id,
  };
};
export const getExectionReadinessReviewDetailsSuccess = (response) => {
  return {
    type: GET_EXECUTION_READINESS_REVIEW_DETAILS_SUCCESS,
    response,
  };
};
export const postExecutionReadinessCustomerInputDetails = (
  bi_id,
  product_id,
  question_id,
  vertical_id,
  action,
  name,
  input_id,
  ans_type
) => {
  return {
    type: POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS,
    bi_id,
    product_id,
    question_id,
    vertical_id,
    action,
    name,
    input_id,
    ans_type,
  };
};
export const postExecutionReadinessCustomerInputDetailsSuccess = (response) => {
  return {
    type: POST_EXECUTION_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS,
    response,
  };
};
export const postExecutionReadinessCustomerAnswerChoiceDetails = (
  function_id,
  question_id,
  answer_id,
  is_master,
  function_area_id,
  bi_id,
  product_id,
  option_title
) => {
  return {
    type: POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS,
    function_id,
    question_id,
    answer_id,
    is_master,
    function_area_id,
    bi_id,
    product_id,
    option_title,
  };
};
export const postExecutionReadinessCustomerAnswerChoiceDetailsSuccess = (
  response
) => {
  return {
    type: POST_EXECUTION_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS,
    response,
  };
};

export const getStrategyReadinessExistedFunctionsDetails = (
  bi_id,
  product_id,
  is_active = false
) => {
  return {
    type: GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS,
    bi_id,
    product_id,
    is_active,
  };
};
export const getStrategyReadinessExistedFunctionsDetailsSuccess = (
  response
) => {
  return {
    type: GET_STRATEGY_READINESS_EXISTED_FUNCTION_DETAILS_SUCCESS,
    response,
  };
};

export const postNewStrategyReadinessFunctions = (data, stage_type) => {
  return {
    type: POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS,
    data,
    stage_type,
  };
};

export const postNewStrategyReadinessFunctionsSuccess = (response) => {
  return {
    type: POST_NEW_STRATEGY_READINESS_FUNCTION_DETAILS_SUCCESS,
    response,
  };
};

export const setAllStrategyQuestions = (response) => {
  return {
    type: GET_STRATEGY_READINESS_FUNCTIONALAREA,
    allStrategyQuestionsList: response,
  };
};

// export const postStrategyReadinessFunctionalAreaDetails = (
//   function_id,
//   function_area_id,
//   question_id,
//   answer_id,
//   is_master
// ) => {
//   return {
//     type: POST_STRATEGY_READINESS_FUNCTIONALAREA,
//     function_id,
//     function_area_id,
//     question_id,
//     answer_id,
//     is_master,
//   };
// };

// export const postStrategyReadinessFunctionalAreaDetailsSuccess = (
//   response
// ) => {
//   return {
//     type: POST_STRATEGY_READINESS_FUNCTIONALAREA_SUCCESS,
//     response,
//   };
// };

export const getStrategyReadinessFunctionalAreaDetails = (
  function_id,
  bi_id,
  product_id,
  main_screen_id,
  screen_id
) => {
  return {
    type: GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA,
    function_id,
    bi_id,
    product_id,
    main_screen_id,
    screen_id,
  };
};

export const getStrategyReadinessFunctionalAreaDetailsSuccess = (response) => {
  return {
    type: GET_STRATEGY_READINESS_FUNCTIONALAREA_DATA_SUCCESS,
    response,
  };
};

export const getStrategyReadinessReviewDetails = (bi_id, product_id) => {
  return {
    type: GET_STRATEGY_READINESS_REVIEW_DETAILS,
    bi_id,
    product_id,
  };
};
export const getStrategyReadinessReviewDetailsSuccess = (response) => {
  return {
    type: GET_STRATEGY_READINESS_REVIEW_DETAILS_SUCCESS,
    response,
  };
};

export const postStrategyReadinessCustomerInputDetails = (
  bi_id,
  product_id,
  question_id,
  vertical_id,
  action,
  name,
  input_id,
  ans_type
) => {
  return {
    type: POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS,
    bi_id,
    product_id,
    question_id,
    vertical_id,
    action,
    name,
    input_id,
    ans_type,
  };
};
export const postStrategyReadinessCustomerInputDetailsSuccess = (response) => {
  return {
    type: POST_STRATEGY_READINESS_CUSTOMER_INPUT_DETAILS_SUCCESS,
    response,
  };
};

export const postStrategyReadinessCustomerAnswerChoiceDetails = (
  function_id,
  question_id,
  answer_id,
  is_master,
  function_area_id,
  bi_id,
  product_id,
  option_title
) => {
  return {
    type: POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS,
    function_id,
    question_id,
    answer_id,
    is_master,
    function_area_id,
    bi_id,
    product_id,
    option_title,
  };
};
export const postStrategyReadinessCustomerAnswerChoiceDetailsSuccess = (
  response
) => {
  return {
    type: POST_STRATEGY_READINESS_CUSTOMER_ANSWER_CHOICE_DETAILS_SUCCESS,
    response,
  };
};

export const setSelectedHelpInfoSidebar = (value, payload) => {
  return {
    type: SET_SELECTED_HELP_INFO_SIDEBAR,
    value,
    payload,
  };
};
export const setSelectedpermission = (value) => {
  return {
    type: SET_SELECTED_PERMISSION,
    value,
  };
};
export const setSelectedSubMenupermission = (value) => {
  return {
    type: SET_SELECTED_SUB_MENU_PERMISSION,
    value,
  };
};
export const postFolderManagerAddNew = (parent_folder_id, folder_name) => {
  return {
    type: POST_FOLDER_MANAGER_ADD_NEW,
    parent_folder_id,
    folder_name,
  };
};
export const postFolderManagerAddNewSuccess = (response) => {
  return {
    type: POST_FOLDER_MANAGER_ADD_NEW_SUCCESS,
    response,
  };
};
export const postFolderManagerEdit = (folder_id, folder_name) => {
  return {
    type: POST_FOLDER_MANAGER_EDIT,
    folder_id,
    folder_name,
  };
};
export const postFolderManagerEditSuccess = (response) => {
  return {
    type: POST_FODER_MANAGER_EDIT_SUCCESS,
    response,
  };
};

export const postFolderManagerDelete = (folder_id) => {
  return {
    type: POST_FOLDER_MANAGER_DELETE,
    folder_id,
  };
};
export const postFolderManagerDeleteSuccess = (response) => {
  return {
    type: POST_FOLDER_MANAGER_DELETE_SUCCESS,
    response,
  };
};

export const postUploadFileInSpecificFolder = (parent_folder_id, file) => {
  return {
    type: POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER,
    parent_folder_id,
    file,
  };
};
export const postUploadFileInSpecificFolderSuccess = (response) => {
  return {
    type: POST_UPLOAD_FILE_IN_SPECIFIC_FOLDER_SUCCESS,
    response,
  };
};
export const getDriveFiles = (bi_id, screen_id, main_screen_id) => {
  return {
    type: GET_DRIVE_FILES,
    bi_id,
    screen_id,
    main_screen_id,
  };
};

export const getDriveFilesSuccess = (response) => {
  return {
    type: GET_DRIVE_FILES_SUCCESS,
    response,
  };
};
export const getProjectSummaryDetails = (bi_id, product_id, gantt_chart) => {
  return {
    type: GET_PROJECT_SUMMARY_DETAILS,
    bi_id,
    product_id,
    gantt_chart,
  };
};

export const getProjectSummaryDetailsSuccess = (response) => {
  return {
    type: GET_PROJECT_SUMMARY_DETAILS_SUCCESS,
    response,
  };
};

export const postLocalFolderManager = (
  bi_id,
  screen_id,
  main_screen_id,
  file,
  question_id,
  function_id,
  product_id,
  task_id
) => {
  return {
    type: POST_LOCAL_FOLDER_MANAGER,
    bi_id,
    screen_id,
    main_screen_id,
    file,
    question_id,
    function_id,
    product_id,
    task_id
  };
};

export const postLocalFolderManagerSuccess = (response) => {
  return {
    type: POST_LOCAL_FOLDER_MANAGER_SUCCESS,
    response,
  };
};

export const getLocalFolderManager = (
  bi_id,
  screen_id,
  main_screen_id,
  global_files
) => {
  return {
    type: GET_LOCAL_FOLDER_MANAGER,
    bi_id,
    screen_id,
    main_screen_id,
    global_files,
  };
};

export const getLocalFolderManagerSuccess = (response) => {
  return {
    type: GET_LOCAL_FOLDER_MANAGER_SUCCESS,
    response,
  };
};

export const postLocalFolderManagerFileDelete = (folder_id) => {
  return {
    type: POST_LOCAL_FOLDER_MANAGER_FILE_DELETE,
    folder_id,
  };
};
export const postLocalFolderManagerFileDeleteSuccess = (response) => {
  return {
    type: POST_LOCAL_FOLDER_MANAGER_FILE_DELETE_SUCCESS,
    response,
  };
};

export const getScreenComments = (bi_id, main_screen_id, screen_id) => {
  return {
    type: GET_SCREEN_COMMENTS,
    bi_id,
    main_screen_id,
    screen_id,
  };
};

export const getScreenCommentsSuccess = (response) => {
  return {
    type: GET_SCREEN_COMMENTS_SUCCESS,
    response,
  };
};

export const postScreenComments = (
  content,
  bi_id,
  main_screen_id,
  screen_id,
  action,
  comment_id
) => {
  return {
    type: POST_SCREEN_COMMENTS,
    content,
    bi_id,
    main_screen_id,
    screen_id,
    action,
    comment_id,
  };
};

export const postScreenCommentsSuccess = (response) => {
  return {
    type: POST_SCREEN_COMMENTS_SUCCESS,
    response,
  };
};
export const setTemplateButtonSelected = (value) => {
  return {
    type: SET_TEMPLATE_BTN_SELECTED,
    value,
  };
};

export const getBiFGoalStatus = (bi_id, product_id, fun_goal_id) => {
  return {
    type: GET_BI_FGOAL_STATUS,
    bi_id,
    product_id,
    fun_goal_id,
  };
};
export const getBiFGoalStatusSuccess = (response) => {
  return {
    type: GET_BI_FGOAL_STATUS_SUCCESS,
    response,
  };
};

export const postBiFGoalStatus = (bi_id, product_id, fun_goal_id, data) => {
  return {
    type: POST_BI_FGOAL_STATUS,
    bi_id,
    product_id,
    fun_goal_id,
    data,
  };
};

export const postBiFGoalStatusSuccess = (response) => {
  return {
    type: POST_BI_FGOAL_STATUS_SUCCESS,
    response,
  };
};

export const getGlobalSearchFilter = (bi_id, product_id) => {
  return {
    type: GET_GLOBAL_SERACH_FILTER,
    bi_id,
    product_id,
  };
};
export const getGlobalSearchFilterSuccess = (response) => {
  return {
    type: GET_GLOBAL_SERACH_FILTER_SUCCESS,
    response,
  };
};

export const getIsMaintenanceMode = () => {
  return {
    type: GET_IS_MAINTENANCE_MODE,
  };
};
export const getIsMaintenanceModeSuccess = (response) => {
  return {
    type: GET_IS_MAINTENANCE_MODE_SUCCESS,
    response,
  };
};

export const SetIsMaintenanceMode = (value) => {
  return {
    type: IS_MAINTENANCE_MODE,
    value,
  };
};

export const getXLStrategyPlanDetails = (bi_id, product_id) => {
  return {
    type: GET_XLSTRATEGYPLAN_DETAILS,
    bi_id,
    product_id,
  };
};
export const getXLStrategyPlanDetailsSuccess = (response) => {
  return {
    type: GET_XLSTRATEGYPLAN_DETAILS_SUCCESS,
    response,
  };
};
export const postXLStrategyPlanDetails = (bi_id, product_id, data) => {
  return {
    type: POST_XLSTRATEGYPLAN_DETAILS,
    bi_id,
    product_id,
    data,
  };
};

export const postXLStrategyPlanDetailsSuccess = (response) => {
  return {
    type: POST_XLSTRATEGYPLAN_DETAILS_SUCCESS,
    response,
  };
};
export const getExportWorkboardCsvfile = (bi_id, product_id, tool_name) => {
  return {
    type: GET_EXPORT_WORBOARD_CSV_FILE,
    bi_id,
    product_id,
    tool_name,
  };
};

export const getExportWorkboardCsvfileSuccess = (response) => {
  return {
    type: GET_EXPORT_WORBOARD_CSV_FILE_SUCCESS,
    response,
  };
};