import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import MainComponent from "../../MainComponent.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { getRandomColor, createImageFromInitials } from "../../Utils";
import "./BIDetails.css";
import { url } from "../../../store/sagas/API.js";
import {
  biDetails,
  biDetailsPost,
  bisubsectorGet,
  postLocalFolderManager,
} from "../../../store/actions/index.js";
import OtherImage from "../../../assets/images/Othericon.svg";
import HelpInfo from "../../../utils/helpInfoProviderComponent.js";
import keys from "../../../utils/helpInfoKeys.json";
import SideNavHelpInfo from "../../../utils/SideNavHelpInfo.js";
import Breadcrumb from "../../Breadcrumb.js";
import CommentSection from "../../../utils/CommentSection.js";
import Login from "../../Login.js";
import UploadFiles from "../../UploadFiles.js";

function BIDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ls_bi_id = localStorage.getItem("bi_id");
  const isBISubmitted = useSelector((state) => state.isBISubmitted);
  const isBIGetFetched = useSelector((state) => state.isBIGetFetched);
  const biDetailsPostSucess = useSelector((state) => state.biDetailsPostSucess);
  const biDetailsGetSuccess = useSelector((state) => state.biDetailsGetSuccess);
  const selectedPermission = useSelector((state) => state.selectedPermission);
  const [isClicked, setIsClicked] = useState(false);
  const [biSectorData, setBiSectorData] = useState([]);
  const [biSubSectorData, setBiSubSectorData] = useState([]);
  const [biBusinesstypeData, setBiBusinessTypeData] = useState([]);
  const isSectorChanged = useSelector((state) => state.isSectorChanged);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  // const [selectedBiType, setselectedBiType] = useState(null);
  const [selectedSubSector, setSelectedSubSector] = useState([]);
  const [emp_data, setEmpData] = useState([]);
  const [sectorChange, setSectorChange] = useState(false);
  const [biObjective, setBIObjective] = useState("");
  const [biDescription, setBIDescription] = useState(null);
  const [biName, setBIName] = useState(null);
  const [biStartDate, setBIStartDate] = useState(null);
  const [biTargetDate, setBITargetDate] = useState(null);
  const [excelrateLead, setExcelrateLead] = useState(null);
  const uploadFilesRef = useRef(null);
  const [disableNext, setDisableNext] = useState(false);
  const [showUploadComp, setShowUploadComp] = useState(false);

  const isBISubSectorFetched = useSelector(
    (state) => state.isBISubSectorFetched
  );
  const biSubSectorGetDetails = useSelector(
    (state) => state.biSubSectorGetDetails
  );
  const fetchpostLocalManagerData = useSelector(
    (state) => state.fetchpostLocalManagerData
  );
  const selectedNewBI = useSelector((state) => state.selectedNewBI);
  const selectedHelpInfoSidebar = useSelector(
    (state) => state.selectedHelpInfoSidebar
  );
  useEffect(() => {
    if (selectedNewBI === false) {
      setSelectedOption([]);
      setBIObjective("");
      setBIDescription("");
      setBIName("");
      setSelectedSector("");
      setSelectedSubSector("");
      setBIStartDate("");
      setBITargetDate("");
      setExcelrateLead("");
      // setselectedBiType("");
    }
  }, [selectedNewBI]);

  const BIcustomStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      paddingLeft: 0,
      borderRadius: "10px",
    }),
    img: {
      height: 40,
    },
  };
  useEffect(() => {
    dispatch(biDetails(localStorage.getItem("bi_id")));
    return () => {};
  }, []);

  useEffect(() => {
    if (isBIGetFetched) {
      if (biDetailsGetSuccess) {
        if (biDetailsGetSuccess.ok) {
          if (localStorage.getItem("bi_id")) {
            if (biDetailsGetSuccess.data.bi_data) {
              setBIName(biDetailsGetSuccess.data.bi_data[0].bi_name);
              setExcelrateLead(
                biDetailsGetSuccess.data.bi_data[0].excel_lead_name
              );
              setBIObjective(biDetailsGetSuccess.data.bi_data[0].objective);
              setBIDescription(biDetailsGetSuccess.data.bi_data[0].description);
              setBIStartDate(
                biDetailsGetSuccess.data.bi_data[0].start_date
                  ? biDetailsGetSuccess.data.bi_data[0].start_date.split(" ")[0]
                  : null
              );
              setBITargetDate(
                biDetailsGetSuccess.data.bi_data[0].target_date
                  ? biDetailsGetSuccess.data.bi_data[0].target_date.split(
                      " "
                    )[0]
                  : null
              );
            }
          }
          if (biDetailsGetSuccess.data.mst_sec) {
            let selectedSectors = [];
            setBiSectorData(
              biDetailsGetSuccess.data.mst_sec.map((obj) => {
                if (obj.isselected === "true") {
                  selectedSectors.push({
                    value: obj.id,
                    label: obj.prop_name,
                    icon: obj.icon ? url + obj.icon : OtherImage,
                  });
                }
                return {
                  value: obj.id,
                  label: obj.prop_name,
                  icon: obj.icon ? url + obj.icon : OtherImage,
                };
              })
            );
            setSelectedSector(selectedSectors);
          }
          if (biDetailsGetSuccess.data.sub_sec) {
            let selectedSubSectors = [];
            setBiSubSectorData(
              biDetailsGetSuccess.data.sub_sec.map((obj, i) => {
                if (obj.isselected === "true") {
                  selectedSubSectors.push({
                    value: obj.id,
                    label: obj.prop_name,
                    parent: obj.sec_name,
                    icon: obj.icon ? url + obj.icon : OtherImage,
                  });
                }
                return {
                  value: obj.id,
                  label: obj.prop_name,
                  parent: obj.sec_name,
                  icon: obj.icon ? url + obj.icon : OtherImage,
                };
              })
            );
            setSelectedSubSector(selectedSubSectors);
          }
          // if (biDetailsGetSuccess.data.bus_type) {
          //   setBiBusinessTypeData(
          //     biDetailsGetSuccess.data.bus_type.map((obj, i) => {
          //       if (obj.isselected === "true") {
          //         setselectedBiType({
          //           value: obj.id,
          //           label: obj.prop_name,
          //           icon: obj.icon ? url + obj.icon : OtherImage,
          //         });
          //       }
          //       return {
          //         value: obj.id,
          //         label: obj.prop_name,
          //         icon: obj.icon ? url + obj.icon : OtherImage,
          //       };
          //     })
          //   );
          // }
          if (biDetailsGetSuccess.data.emp_data) {
            setEmpData(
              biDetailsGetSuccess.data.emp_data.map((obj) => {
                if (obj.isselected === "true") {
                  setSelectedOption({
                    value: obj.id,
                    label: obj.prop_name,
                    icon: createImageFromInitials(
                      30,
                      obj.prop_name,
                      getRandomColor(obj.prop_name)
                    ),
                  });
                }
                return {
                  value: obj.id,
                  label: obj.prop_name,
                  icon: createImageFromInitials(
                    30,
                    obj.prop_name,
                    getRandomColor(obj.prop_name)
                  ),
                };
              })
            );
          }
        }
      }
    }
    return () => {};
  }, [biDetailsGetSuccess]);
  useEffect(() => {
    if (isBISubSectorFetched) {
      if (biSubSectorGetDetails) {
        if (biSubSectorGetDetails.ok) {
          let selectedSubSectors = [];
          setBiSubSectorData(
            biSubSectorGetDetails.data.data.map((obj, i) => {
              if (selectedSubSector.some((sub) => obj.id === sub.value)) {
                selectedSubSectors.push({
                  value: obj.id,
                  label: obj.prop_name,
                  parent: obj.sec_name,
                  icon: obj.icon ? url + obj.icon : OtherImage,
                });
              }
              return {
                value: obj.id,
                label: obj.prop_name,
                parent: obj.sec_name,
                icon: obj.icon ? url + obj.icon : OtherImage,
              };
            })
          );
          setSelectedSubSector(selectedSubSectors);
        } else {
          console.log(biSubSectorGetDetails.data);
        }
      } else {
        console.log("Not able to fetch the data");
      }
    }
  }, [isSectorChanged, isBISubSectorFetched]);

  const SendData = (e) => {
    e.preventDefault();
    if (selectedPermission === "write" || selectedPermission === "admin") {
      dispatch(
        biDetailsPost(
          localStorage.getItem("bi_id"),
          e.target.giname.value,
          e.target.emp_id.value,
          selectedSector,
          selectedSubSector,
          e.target.obj.value,
          e.target.desc.value || "",
          e.target.start_dt.value,
          e.target.target_dt.value
          // e.target.selectedbitype.value
        )
      );
      setIsClicked(true);
    }
  };

  useEffect(() => {
    if (isBISubmitted && isClicked) {
      if (biDetailsPostSucess) {
        if (biDetailsPostSucess.ok) {
          setShowUploadComp(true);
          if (localStorage.getItem("bi_id")) {
          } else {
            localStorage.setItem("bi_id", biDetailsPostSucess.data.bi_id);
            if (!ls_bi_id) {
              setDisableNext(true);
            }
          }
        }
      }
    }
  }, [isBISubmitted]);
  useEffect(() => {
    if (showUploadComp && uploadFilesRef.current && !ls_bi_id) {
      uploadFilesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showUploadComp]);
  const handleSelect = (e) => {
    const selectedValues = e.map((option) => option.value);
    setSelectedSector(e);
    setSectorChange(!sectorChange);
    // setSelectedSubSector(null);
    const data = {
      bi_id: localStorage.getItem("bi_id") || null,
      sec_name: selectedValues,
    };
    dispatch(bisubsectorGet(data, sectorChange));
  };

  // handle onChange event of the dropdown
  const handleChange = (e) => {
    setSelectedOption(e);
  };
  const handleSubSectorSelect = (e) => {
    setSelectedSubSector(e);
  };
  const handleBackButton = () => {
    navigate("/dashboard", { replace: true });
    localStorage.setItem("newbuttonClicked", JSON.stringify(false));
  };
  const handleNextButton = () => {
    navigate("/biproductdetails", { replace: true });
    window.location.reload();
  };
  // const handleBiBusinessTypeSelect = (e) => {
  //   setselectedBiType(e);
  // };

  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      display: "inline-block",
      border: "1px solid #ccc",
      boxSizing: "border-box",
      paddingLeft: 0,
      borderRadius: "10px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "var(--text-font-size)", // Adjust the font size as needed
    }),
    img: {
      height: 40,
    },
  };

  var content = (
    <>
      <div
        style={{
          position: "absolute",
          top: "80px",
          backgroundColor: "white",
          width: "100%",
          zIndex: "1",
        }}
      >
        <Breadcrumb
          // SecondSideBarClicked={(value) => setIsSecSideBarOpen(value)}
          // isSecSideBarOpen={isSecSideBarOpen}
          breadcrumbItems={[
            {
              text: "Onboarding",
            },
            {
              text: "BI Details",
            },
            {
              text: undefined,
            },
          ]}
        />
      </div>
      <Form className="bidetails-form" onSubmit={SendData}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              "&::WebkitScrollbar": { display: "none" },
              fontSize: "var(--sub-heading-font-size)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "var(--main-heading)",
                  fontWeight: "var(--heading-font-weight)",
                  marginRight: "5px",
                }}
              >
                Tell us about your Business Initiative
              </div>
            </div>
            <br />
            <div style={{ alignItems: "center" }}>
              <Form.Group className="bidetails-input-container">
                <Form.Label className="bi_details-title">
                  Business Initiative Name<span className="required">*</span>
                  <HelpInfo
                    documentName={"BI Details"}
                    helpKey={keys["BI Details"].Name}
                    image={"flashy"}
                  />
                </Form.Label>
                <Form.Control
                  name="giname"
                  type="text"
                  placeholder="e.g. Marketing Plan"
                  value={biName}
                  onChange={(e) => setBIName(e.target.value)}
                  required
                  style={{
                    borderRadius: 10,
                    fontSize: "var(--text-font-size)",
                  }}
                  autoComplete="off"
                  disabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Form.Group className="bidetails-input-container">
                <Form.Label className="bi_details-title">
                  Owner of Business Initiative
                  <span className="required">*</span>
                  {/* <HelpInfo
                    documentName={"BI Details"}
                    helpKey={keys["BI Details"].Owner}
                  /> */}
                </Form.Label>
                <Select
                  required
                  name="emp_id"
                  styles={customStyles}
                  className="form-control selectr"
                  placeholder="Assign owner to Business Initiative"
                  value={selectedOption}
                  options={emp_data}
                  onChange={handleChange}
                  isSearchable={true}
                  filterOption={customFilter}
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img alt="" src={e.icon} />
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: "var(--text-font-size)",
                        }}
                      >
                        {e.label}
                      </span>
                    </div>
                  )}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    // Option: EmpIconOption,
                  }}
                  isDisabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
              {/* <Form.Group className="bidetails-select-sec-container">
                <Form.Label className="bi_details-title">
                  Excelrate Lead{" "}
                  <HelpInfo
                    documentName={"BI Details"}
                    helpKey={keys["BI Details"].XLLead}
                  />
                </Form.Label>
                <Form.Control
                  name="leadname"
                  type="text"
                  placeholder="Excelrate Lead Name"
                  value={excelrateLead}
                  onChange={(e) => setExcelrateLead(e.target.value)}
                  required
                  style={{
                    borderRadius: 10,
                    height: "38px",
                    fontSize: "var(--text-font-size)",
                  }}
                  disabled
                />
              </Form.Group> */}
            </div>
            <div style={{ display: "flex" }}>
              <Form.Group className="bidetails-select-container">
                <Form.Label className="bi_details-title">
                  Target Sectors<span className="required">*</span>
                  <HelpInfo
                    documentName={"BI Details"}
                    helpKey={keys["BI Details"].Sector}
                    image={"flashy"}
                  />
                </Form.Label>
                <Select
                  required
                  isMulti
                  name="sector"
                  styles={customStyles}
                  className="form-control selectr"
                  placeholder="Select Sector"
                  value={selectedSector}
                  options={biSectorData}
                  onChange={handleSelect}
                  isSearchable={true}
                  filterOption={customFilter}
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img alt="" style={{ height: 30 }} src={e.icon} />
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: "var(--text-font-size)",
                        }}
                      >
                        {e.label}
                      </span>
                    </div>
                  )}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    // Option: IconOption,
                  }}
                  isDisabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
              <Form.Group className="bidetails-select-sec-container">
                <Form.Label className="bi_details-title">
                  Target Sub Sectors<span className="required">*</span>
                  <HelpInfo
                    documentName={"BI Details"}
                    helpKey={keys["BI Details"].SubSector}
                    image={"flashy"}
                  />
                </Form.Label>
                <Select
                  required
                  isMulti
                  name="subsector"
                  styles={customStyles}
                  className="form-control selectr"
                  placeholder="Select Sub Sector"
                  value={selectedSubSector}
                  options={biSubSectorData}
                  onChange={handleSubSectorSelect}
                  isSearchable={true}
                  filterOption={customFilter}
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img alt="" style={{ height: 30 }} src={e.icon} />
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: "var(--text-font-size)",
                        }}
                      >
                        {e.label}
                      </span>
                    </div>
                  )}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    // Option: IconOption,
                  }}
                  isDisabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
            </div>
            {/* <div className="bidetails-input-container">
              <Form.Group>
                <Form.Label className="bi_details-title">
                  BI Business Type<span className="required">*</span>
                  <HelpInfo
                    documentName={"BI Details"}
                    helpKey={keys["BI Details"].BType}
                  />
                </Form.Label>
                <Select
                  required
                  name="selectedbitype"
                  styles={BIcustomStyles}
                  className="form-control selectr"
                  placeholder="Select BI Business Type"
                  value={selectedBiType}
                  options={biBusinesstypeData}
                  onChange={handleBiBusinessTypeSelect}
                  isSearchable={true}
                  filterOption={customFilter}
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img alt="" style={{ height: 30 }} src={e.icon} />
                      <span
                        style={{
                          marginLeft: 5,
                          fontSize: "var(--text-font-size)",
                        }}
                      >
                        {e.label}
                      </span>
                    </div>
                  )}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    // Option: IconOption,
                  }}
                  isDisabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
            </div> */}
            <div>
              <Form.Group className="bidetails-input-container">
                <Form.Label className="bi_details-title">
                  Objective<span className="required">*</span>
                  <HelpInfo
                    documentName={"BI Details"}
                    helpKey={keys["BI Details"].Objective}
                    image={"flashy"}
                  />
                </Form.Label>
                <Form.Control
                  name="obj"
                  as="textarea"
                  rows={3}
                  placeholder="Write an Objective"
                  required
                  value={biObjective}
                  onChange={(e) => setBIObjective(e.target.value)}
                  style={{
                    borderRadius: 10,
                    fontSize: "var(--text-font-size)",
                  }}
                  autoComplete="off"
                  disabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className="bidetails-input-container">
                <Form.Label className="bi_details-title">
                  Description<span className="required">*</span>
                  <HelpInfo
                    documentName={"BI Details"}
                    helpKey={keys["BI Details"].Description}
                  />
                </Form.Label>
                <Form.Control
                  name="desc"
                  as="textarea"
                  rows={3}
                  placeholder="Write a short Description about the Business Initiative"
                  // required
                  value={biDescription}
                  onChange={(e) => setBIDescription(e.target.value)}
                  style={{
                    borderRadius: 10,
                    fontSize: "var(--text-font-size)",
                  }}
                  autoComplete="off"
                  disabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
            </div>
            <div style={{ display: "flex" }}>
              <Form.Group className="bidetails-select-container">
                <Form.Label className="bi_details-title">
                  <span>Start Date(Optional)</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="start_dt"
                  placeholder="DD/MM/YYYY"
                  value={biStartDate}
                  onChange={(e) => setBIStartDate(e.target.value)}
                  style={{
                    borderRadius: 10,
                    fontSize: "var(--text-font-size)",
                    width: "100%",
                  }}
                  disabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
              <Form.Group className="bidetails-select-sec-container">
                <Form.Label className="bi_details-title">
                  Target Date(Optional)
                </Form.Label>
                <Form.Control
                  type="date"
                  name="target_dt"
                  palceholder="DD/MM/YYYY"
                  value={biTargetDate}
                  onChange={(e) => setBITargetDate(e.target.value)}
                  style={{
                    borderRadius: 10,
                    width: "100%",
                    fontSize: "var(--text-font-size)",
                  }}
                  disabled={
                    selectedPermission === "write" ||
                    selectedPermission === "admin"
                      ? false
                      : true
                  }
                />
              </Form.Group>
            </div>
            {showUploadComp && !ls_bi_id && (
              <Form.Label
                className="bi_details-title"
                style={{ marginTop: "10px" }}
              >
                <span>Please upload strategy related documents</span>
              </Form.Label>
            )}
            {ls_bi_id && (
              <Form.Label
                className="bi_details-title"
                style={{ marginTop: "10px" }}
              >
                <span>Please upload strategy related documents</span>
              </Form.Label>
            )}
          </div>
          <div
            className="main-footer"
            style={{
              display: "flex",
              alignItems: "center",
              float: "right",
              position: "absolute",
              top: "70px",
              right: "0px",
              zIndex: 2,
            }}
          >
            <Button type="submit" className="save-button-style-bidetails">
              Save
            </Button>
            <Button onClick={handleBackButton} className="back-button-style">
              Back
            </Button>
            <Button
              onClick={handleNextButton}
              className="next-button-style"
              disabled={!disableNext && !ls_bi_id}
            >
              Next
            </Button>
            <span style={{ paddingLeft: "20px" }}>
              <HelpInfo
                documentName={"BI Details"}
                helpKey={keys["BI Details"].BiInfo}
                image={"question"}
              />
            </span>
          </div>
        </div>
      </Form>
      {showUploadComp && !ls_bi_id && (
        <div className="bidetails-upload-container" ref={uploadFilesRef}>
          <UploadFiles selectedSubMenuPermission={selectedPermission} />
        </div>
      )}
      {ls_bi_id && (
        <div className="bidetails-upload-container" ref={uploadFilesRef}>
          <UploadFiles selectedSubMenuPermission={selectedPermission} />
        </div>
      )}
      <CommentSection />
      {selectedHelpInfoSidebar && <SideNavHelpInfo />}
    </>
  );

  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <>
        <MainComponent
          maincontent={content}
          showTemplate={true}
          showError={true}
          showSelector={true}
          showButtons={false}
          breadcrumbParent={"Onboarding"}
          breadcrumbChild={"BI Details"}
          showFullScreen={true}
          // handleNext={SendData}
          // handleBackButton={handleBackButton}
        />
      </>
    );
  }
}

export default BIDetails;
