import React, { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const IdleTimeout = () => {
  const navigate = useNavigate();
  const handleOnIdle = (event) => {
    Cookies.remove("sid");
    localStorage.clear();
    navigate("/");
  };
  useIdleTimer({
    timeout: 1000 * 60 * 60 * 6,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  return <div></div>;
};
export default IdleTimeout;
